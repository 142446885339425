import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { eyeBrowStickySOTA2021 } from '../../../utils/stickyElement';

const Section = styled.div`
  z-index: 10;
`;

const Wedge = styled.div`
  background-color: #ffffff;
  width: 101%;
  margin-left: -1px;
  height: 10px;

  @media (min-width: 992px) {
    height: 88px;
  }
`;

const OrangeLine = styled.div`
  /* width: 97px; */
  width: 0px;
  height: 2px;
  background-color: rgb(242, 107, 58);
  margin: 0 0 40px 0;
  opacity: 0;
  transition: 0.3s;
`;

const Title = styled.h2`
  margin-bottom: 4px;
  transition: 0.3s;
  font-size: 48px;
  line-height: 56px;
`;

const BodyContainer = styled.div`
  padding-top: 60px;

  @media (min-width: 992px) {
    border-top: 1px solid #3a3836;
  }
`;

class HeaderRow extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data };
  }

  componentDidMount() {
    const { data } = this.state;
    const { elementId, parentContainerId, underlineId, endElementId } = data;
    // Requires that IDs include # sign in string.. ex ('#elementId')
    eyeBrowStickySOTA2021(
      elementId,
      parentContainerId,
      underlineId,
      24,
      endElementId,
      '#eyebrowBodyContainer'
    );
  }

  render() {
    const { data } = this.state;
    const fontWeight = data.fontWeight ? data.fontWeight : '';
    const fontFamily = data.fontFamily ? data.fontFamily : '';

    return (
      <>
        <Wedge className="d-none d-lg-block" />
        <BodyContainer id="eyebrowBodyContainer">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <Title
                  style={{ fontFamily, fontWeight }}
                  className="d-inline-block"
                >
                  {data.text}
                </Title>
                <OrangeLine id="OrangeLineDivider" />
                {/* {headerType} */}
              </div>
            </div>
          </div>
        </BodyContainer>
      </>
    );
  }
}

const headerRowStickyShrinkStateOfAPI2021 = (data) => (
  <Section
    id={`${data.elementId && data.elementId.replace('#', '')}`}
    style={{ backgroundColor: data.backgroundColor }}
  >
    <HeaderRow data={data} />
  </Section>
);

export default headerRowStickyShrinkStateOfAPI2021;

HeaderRow.propTypes = {
  data: PropTypes.shape({
    fontWeight: PropTypes.string,
    fontFamily: PropTypes.string,
    elementId: PropTypes.string,
    parentContainerId: PropTypes.string,
    underlineId: PropTypes.string,
    endElementId: PropTypes.string,
    alternative: PropTypes.bool,
    text: PropTypes.string
  }).isRequired
};
