import React from 'react';
import styled from 'styled-components';
import { Link, withPrefix } from 'gatsby';
import { UnorderedListStyles } from 'aether-marketing';
import { PostmanGlobe } from '@postman/postman-globe';
import PropTypes from 'prop-types';
import { HydrationProvider, Client } from 'react-hydration-provider';
import { ChartComponent } from './chart2023';
import { SideBySideChartComponent } from './chart2023SideBySideCharts';
import '../../../../static/assets/sota-2023/globeStyle.css';
// eslint-disable-next-line import/no-extraneous-dependencies
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const Section = styled.section`
  ul {
    ${UnorderedListStyles.componentStyle.rules}
    margin-left: 25px;
    li {
      margin-left: 0px;
      p {
        margin-left: 0px !important;
      }
    }
  }
  .hex {
    z-index: -10;
    background-color: transparent;
  }
  .floating-header {
    background-color: transparent;
    z-index: 10;
    padding-bottom: 20px;
    padding-top: 70px;
    margin-left: -8px;
    margin-top: -50px;
    display: flex;
    align-content: center;
  }
  .growth-img {
    margin-left: 150px;
    @media (max-width: 992px) {
      margin-left: 20px;
    }
  }
  .floating-body {
    z-index: -20;
    padding-right: 20px;
    p {
      margin-left: 50px;
    }
  }
  .white-header {
    background-color: ${(props) => props.theme.colors.white} !important;
    width: 100%;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 17px;
    margin-bottom: 0px;
    margin-left: 20px;
    @media (max-width: 992px) {
      margin-top: 32px;
    }
  }
  @media (min-width: 992px) {
    padding: 5px 0 5px 0;
    & .floating-header {
      float: left;
      width: 100%;
    }
  }
  .key {
    margin-left: -23px;
    padding-top: 52px;
    display: flex;
  }
  .key h2 {
    padding-left: 16px;
    margin-bottom: 0px;
  }
  .box {
    background-image: linear-gradient(#ff8e64, #ff8e64);
    background-repeat: no-repeat;
    background-size: 2px 100%, calc(100% - 4px) 100%;
    z-index: 1000;
  }
  .box-globe {
    background-image: linear-gradient(#ff8e64, #ff8e64);
    background-repeat: no-repeat;
    background-size: 2px 100%, calc(100% - 4px) 100%;
    z-index: 1000;
    margin-left: 0.1px;
    .white-header {
      width: 500px;
    }
  }
  article:first-of-type {
    margin-top: -100px;
    .box {
      background-size: 2px 28%, calc(100% - 4px) 100%;
      background-position: left bottom, 4px 110px;
    }
  }
  article:last-of-type {
    .box {
      background-size: 2px 3%, calc(100% - 4px) 100%;
      background-position: left top, 4px 0;
    }
  }
  .feature img {
    height: 80px;
  }
  .postman-globe-info-popup__content__section {
    p {
      margin-left: 0px !important;
    }
  }
  .header-line {
    background-color: #ff8e64;
    width: 2px;
    height: 50px;
    bottom: 55px;
    left: 8px;
    z-index: 30;
    position: absolute;
    @media (max-width: 992px) {
      height: 96px;
      bottom: 50px;
    }
  }
  .header-line:last-of-type {
    @media (max-width: 992px) {
      height: 90px;
      bottom: 53px;
    }
  }
  a.anchor {
    color: black;
    align-items: end; /* align icon to bottom of A tag box */
    img {
      opacity: 0.1;
      transition: all 0.2s ease-in-out 0s;
      padding-left: 7px;
      width: auto;
      height: 1.7em;
    }
  }
  a.anchor:hover {
    text-decoration: none;
    border: none;
    img {
      opacity: 1;
      padding-left: 10px;
    }
  }
`;

const QuoteContainer = styled.div`
  border: 1px solid black;
  padding: 30px 30px 30px 70px;
  box-shadow: 3px 3px 0px #686868;
  border-radius: 15px;
  @media (max-width: 992px) {
    padding: 10px 10px 10px 20px;
  }
`;

const QuoteText = styled.p`
  font-size: 22px;
  text-align: left;
  font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial !important;
  margin-left: 0px !important;
`;

const BlockQuote = styled.div`
  position: relative;
  display: block;
  margin-left: 60px;
  margin-right: 30px;
  @media (max-width: 992px) {
    margin-left: 10px;
    margin-right: 10px;
  }

  &::after,
  &::before {
    position: absolute;
    color: #ffb091;
    z-index: 1;
  }
  &&::before {
    display: block;
    font-size: 48px;
    content: '“';
    top: -15px;
    left: -32px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    @media screen and (max-width: 976px) {
      left: -25px;
    }
  }
  &::after {
    content: '”';
    right: -2.4rem;
    bottom: 2rem;
    font-size: 4.8rem;
    font-family: Georgia, Times, 'Times New Roman', serif;
    @media screen and (max-width: 976px) {
      right: -5px;
      bottom: 18px;
    }
  }
`;

function toIdFormat(str) {
  return str
    .toLowerCase() // Convert the string to lower case
    .trim() // Remove leading/trailing spaces
    .replace(/\s+/g, '-') // Replace spaces with hyphen
    .replace(/[^a-z0-9-]/gi, ''); // Remove any special characters
}

// eslint-disable-next-line consistent-return
function OrangeBorderContainer({ headerMedia, headerTitle, headerBody, section }) {
  return (
    <Section>
      <article>
        <div className="row box">
          <div className="">
            <div className="sticky-top  align-items-center key">
              <img src={headerMedia.src} alt={headerMedia.alt} />
              <h2 className=" h3 ">{headerTitle}</h2>
            </div>
            <div className="floating-body header-body">
              {headerBody && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: headerBody.join('')
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div className="box gradient-border" />
      </article>
      {section.map((item) => (
        <article key={pmUuid()}>
          <div className="row box w-100">
            <div className="w-100">
              <div className="sticky-top floating-header ">
                <div className="box header-line" />
                {item.sectionTitle && (
                  <>
                    <img src={item.sectionHex.src} alt={item.sectionHex.alt} style={{ zIndex: '150' }} className="mt-4 mt-lg-0" />
                    <Link to={`#${toIdFormat(item.sectionTitle)}`} className="white-header row anchor">
                      <h2
                        className="h3 mb-0"
                        style={{
                          paddingTop: '28px',
                          marginTop: '-28px',
                          scrollSnapStop: 'always'
                        }}
                        id={toIdFormat(item.sectionTitle)}
                        dangerouslySetInnerHTML={{
                          __html: item.sectionTitle
                        }}
                      />
                      <img alt="ling icon" className="" src="https://voyager.postman.com/icon/link-icon.svg" />
                    </Link>
                  </>
                )}
              </div>
              <div className="floating-body w-100">
                {item.sectionBody && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.sectionBody.join('')
                    }}
                  />
                )}
                <div className="ml-5 mt-5 mb-5" />
                {item.chart && (
                  <div className="ml-5">
                    <ChartComponent data={item.chart.chartData} />
                  </div>
                )}
                {item.chartRow && (
                  <div className="ml-5">
                    <SideBySideChartComponent data={item.chartRow} />
                  </div>
                )}
                {item.sectionBody2 && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: item.sectionBody2.join('')
                    }}
                  />
                )}
                <HydrationProvider>
                  <Client>
                    <div className="d-none d-md-block">
                      {item.globe && (
                        <div>
                          <div className="row box-globe">
                            <div className="">
                              <div className="sticky-top floating-header ">
                                <img src="https://voyager.postman.com/icon/hexagon-secondary.svg" alt="SOTA section hex" style={{ zIndex: '150' }} className="mt-4 mt-lg-0" />
                                <Link to="#explore-the-world-of-apis" className="white-header row anchor ">
                                  <h2
                                    id="explore-the-world-of-apis"
                                    className="h3 mb-0 "
                                    style={{
                                      paddingTop: '28px',
                                      marginTop: '-28px'
                                    }}
                                  >
                                    Explore the world of APIs
                                  </h2>
                                  <img alt="ling icon" className="" src="https://voyager.postman.com/icon/link-icon.svg" />
                                </Link>
                              </div>
                            </div>
                          </div>
                          <p>Try out our interactive globe! Click on countries to discover the 50 nations where users have created the most requests and collections on the Postman API platform.</p>
                          <div className="ml-5 mt-5 mb-5 ">
                            <PostmanGlobe assetsFolder={withPrefix('/assets/sota-2023')} aspectRatio={1.75} width="" maxWidth="100%" border="" startRotation={[0.72, 0.1]} radius={1.65} outlineThickness={1.1} stars1Seed={93} stars1Amount={400} stars1Size={17.5} stars2Seed={145} stars2Amount={1800} stars2Size={17.5} countryFillNoData="#ffd099" countryFillHasData="#ffb967" countryFillHover="#ff8a01" countryStrokes countryStrokeOpacity={1} infoBoxHeaderColor="#ff8a01" infoBoxShadowOpacity={1} infoBoxShadowDistance={4} infoBoxWidth={310} infoBoxHeight={230} infoBoxScale={0.8} cameraPan={[0.5, 0.25]} cameraPanRotation={2.5} postManautScale={1} postManautPosition={[-1.75, -1.75, 2.0]} postManautFloatSpeed={4} postManautFloatBounds={[0.5, 0.65]} />
                          </div>
                        </div>
                      )}
                    </div>
                  </Client>
                </HydrationProvider>
                {item.quote && (
                  <QuoteContainer className="mx-auto col-11 col-md-10 mb-2">
                    <BlockQuote className="text-center">
                      <QuoteText>{`${item.quote.text}`}</QuoteText>
                      <footer className="text-left">
                        <p className="mb-0">
                          <strong>
                            {`${item.quote.name.first} `}
                            {item.quote.name.last}
                          </strong>
                          {item.quote.name.title && `, ${item.quote.name.title}`}
                        </p>
                      </footer>
                    </BlockQuote>
                  </QuoteContainer>
                )}
              </div>
            </div>
          </div>
        </article>
      ))}
    </Section>
  );
}

OrangeBorderContainer.propTypes = {
  headerMedia: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  headerTitle: PropTypes.string,
  headerBody: PropTypes.arrayOf(PropTypes.string),
  section: PropTypes.arrayOf(
    PropTypes.shape({
      sectionTitle: PropTypes.string,
      sectionBody: PropTypes.arrayOf(PropTypes.string),
      sectionBody2: PropTypes.arrayOf(PropTypes.string),
      sectionHex: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string
      }),
      chart: PropTypes.shape({
        chartData: PropTypes.shape({
          labels: PropTypes.arrayOf(PropTypes.string),
          datasets: PropTypes.arrayOf(
            PropTypes.shape({
              label: PropTypes.string,
              data: PropTypes.arrayOf(PropTypes.number),
              backgroundColor: PropTypes.arrayOf(PropTypes.string)
            })
          )
        })
      })
    })
  )
};

OrangeBorderContainer.defaultProps = {
  headerMedia: {
    src: '',
    alt: ''
  },
  headerTitle: '',
  headerBody: [],
  section: []
};

export default OrangeBorderContainer;
