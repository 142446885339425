// Plain Header (H3) and P tag for sections

import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  background-color: ${(props) => props.theme.colors.white};
  padding-bottom: 60px;
  font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial !important;
`;

const QuoteContainer = styled.div`
  border: 1px solid black;
  padding: 30px 30px 30px 70px;
  box-shadow: 3px 3px 0px #686868;
`;

const QuoteText = styled.p`
  font-size: 22px;
  text-align: left;
  font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, Segoe UI,
    Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial !important;
`;

const BlockQuote = styled.div`
  position: relative;
  display: block;
  margin-left: 60px;
  margin-right: 30px;

  &::after,
  &::before {
    position: absolute;
    color: #ffb091;
    z-index: 1;
  }
  &&::before {
    display: block;
    font-size: 48px;

    content: '“';
    top: -15px;
    left: -32px;
    font-family: Georgia, 'Times New Roman', Times, serif;

    @media screen and (max-width: 576px) {
      left: -25px;
    }
  }
  &::after {
    content: '”';
    right: -2.4rem;
    bottom: 2rem;
    font-size: 4.8rem;
    font-family: Georgia, Times, 'Times New Roman', serif;
    @media screen and (max-width: 576px) {
      right: 0px;
    }
  }
`;

const quoteStateOfAPI2021 = ({ text, name }) => (
  <Section>
    <div className="container">
      <div className="row">
        <QuoteContainer className="mx-auto col-11 col-md-10">
          <BlockQuote className="text-center">
            <QuoteText>{`${text}`}</QuoteText>
            <footer className="text-left">
              <p className="mb-0">
                <strong>
                  {`${name.first} `}
                  {name.last}
                </strong>
                {name.title && `, ${name.title}`}
              </p>
            </footer>
          </BlockQuote>
        </QuoteContainer>
      </div>
    </div>
  </Section>
);

export default quoteStateOfAPI2021;
