/* global $ */
import React from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip
} from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import 'chartjs-plugin-deferred';
import ChartKey2023 from './ChartKey2023';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip);

function isMobileWidth() {
  if ($(window).width() <= 768) {
    return true;
  }
  return false;
}

const callbacks = {
  none(value) {
    return value;
  },
  // Formats hover tooltip as "Label: Percentage" - to nearest decimal
  percentageTooltips(data) {
    const { dataset, dataIndex } = data;
    return `${data.dataset.label}: ${dataset.data[dataIndex]}%`;
  },
  // For the numbers along the X or Y axes
  addCommasToLargeNumsOnAxes(chartJSValue) {
    if (typeof chartJSValue === 'number') {
      let value = chartJSValue; // because linter
      value = value.toString();
      value = value.split(/(?=(?:...)*$)/);
      value = value.join(',');
      return value;
    }
    return chartJSValue;
  },
  // Make axes display % sign (args: value, index, values)
  percentageOnTicks(value) {
    return `${value}%`;
  },
  // For the number values in the hoverable Tool tip, yes.. they are different functions :(
  addCommasToLargeNumsToolTip(tooltipItem, data) {
    const label = data.labels[tooltipItem.index];
    const value =
      data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
    const formattedValue = parseInt(value, 10).toLocaleString();
    return `${label}: ${formattedValue}`;
  }
};

class ChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: null
    };
  }

  componentDidMount() {
    const { isMobile } = this.state;

    if (isMobile === null) {
      this.setState({
        isMobile: isMobileWidth()
      });
    }
    window.addEventListener('resize', () => {
      this.setState({
        isMobile: isMobileWidth()
      });
    });
  }

  prepareChart(data) {
    const { isMobile } = this.state;

    const { chartType } = data;
    const { chartData } = data;
    const { options } = data;

    const { backgroundColor } = chartData.datasets[0];

    // Show hide y axis labels
    if (isMobile) {
      options.scales.y.ticks.display = false;
    } else {
      options.scales.y.ticks.display = true;
    }

    // Note about below, be aware that chartJS option object keys can alternate between "callback" (singular) and "callbacks" (plural)
    if (
      options.scales?.x.ticks.callback &&
      typeof options.scales?.x.ticks.callback === 'string'
    ) {
      options.scales.x.ticks.callback =
        callbacks[options.scales.x.ticks.callback];
    }
    if (
      options.scales?.y.ticks.callback &&
      typeof options.scales?.y.ticks.callback === 'string'
    ) {
      options.scales.y.ticks.callback =
        callbacks[options.scales.y.ticks.callback];
    }
    if (
      options.tooltip.callbacks.label &&
      typeof options.tooltip.callbacks.label === 'string'
    ) {
      options.tooltip.callbacks.label =
        callbacks[options.tooltip.callbacks.label];
    }

    const itemDataWithCommaSeparatedValues = chartData.datasets[0].data.map(
      (value) => {
        return parseInt(value, 10).toLocaleString();
      }
    );

    let chartElement;
    if (chartType === 'doughnut') {
      chartElement = (
        <div role="img" aria-label={data.ariaLabel && data.ariaLabel}>
          <Doughnut
            data={chartData}
            height={300}
            options={options}
            key={pmUuid()}
          />
        </div>
      );
    } else {
      chartElement = (
        <div role="img" aria-label={data.ariaLabel && data.ariaLabel}>
          <Bar data={chartData} options={options} key={pmUuid()} />
        </div>
      );
    }

    let chartSection;
    if (chartType === 'doughnut') {
      chartSection = (
        <div
          className="row mb-4"
          style={{ paddingLeft: '14px' }}
          key={pmUuid()}
        >
          <div className="col-12 col-md-6">{chartElement}</div>
          <div className="col-12 text-center text-md-left col-md-6 mb-4">
            <ChartKey2023
              labels={chartData.labels}
              dataset={itemDataWithCommaSeparatedValues}
              backgroundColor={backgroundColor}
              insertBrAfterItemName
              isPercent={false}
            />
          </div>
        </div>
      );
    }
    // else if (chartData.datasets.length > 1) {
    //   const multiDatasetLabels = chartData.datasets.map((dataset) => dataset.label);
    //   const multiDatasetBackgroundColor = chartData.datasets.map((dataset) => dataset.backgroundColor);
    //     chartSection = (
    //       <div className="row" style={{paddingLeft: '16px'}}>
    //         <div className="col-12 mb-4">{chartElement}</div>
    //           <div className="col-12 text-center mb-4 mb-md-1">
    //             <ChartKey2023
    //               labels={multiDatasetLabels}
    //               dataset={chartData.datasets[0].data}
    //               backgroundColor={multiDatasetBackgroundColor}
    //               displayLabelOnlyAndOmitValues
    //             />
    //         </div>
    //       </div>
    //     )
    // } else {
    //   chartSection = (
    //     <div className="row" style={{paddingLeft: '16px'}}>
    //       <div className="col-12">{chartElement}</div>
    //         <div className="col-12 text-center mb-4 mb-md-1 d-md-none pt-4 pt-md-0">
    //             <ChartKey2023
    //               labels={chartData.labels}
    //               dataset={chartData.datasets[0].data}
    //               backgroundColor={[]}
    //             />
    //       </div>
    //     </div>
    //   )
    // }

    return chartSection;
  }

  render() {
    const { data } = this.props;

    const row1 = data.chartRow1.map((chartData) => {
      return this.prepareChart(chartData);
    });
    const row2 = data.chartRow2.map((chartData) => {
      return this.prepareChart(chartData);
    });

    return (
      <div className="row">
        <div className="col-12 col-md-6">
          <h5 className="text-center mb-3">Number of Collections Created</h5>
          {row1.map((chart) => {
            return chart;
          })}
        </div>
        <div className="col-12 col-md-6 mb-4">
          <h5 className="text-center mb-3">Number of Requests Created</h5>
          {row2.map((chart) => {
            return chart;
          })}
        </div>
      </div>
    );
    // return (
    //   <>
    //     {chartSection}
    //       <div className="row" style={{ marginBottom: '30px' }}>
    //         {subtext && (
    //           <div className="col-12 mt-4">
    //             <SubtextStyles className="mb-0 text-center">
    //               {subtext}
    //             </SubtextStyles>
    //           </div>
    //         )}
    //       </div>
    //   </>
    // );
  }
}

const globalGrowth2022Section = (data) => (
  <div className="container">
    <ChartComponent data={data} />
  </div>
);

ChartComponent.propTypes = {
  data: PropTypes.shape({
    chartRow1: PropTypes.instanceOf(Array),
    chartRow2: PropTypes.instanceOf(Array),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        amount: PropTypes.number
      })
    ),
    beginAtZero: PropTypes.bool,
    solidColor: PropTypes.string,
    xLabelType: PropTypes.string,
    downloadUrl: PropTypes.string,
    shareUrl: PropTypes.string,
    fontFamily: PropTypes.string,
    chartType: PropTypes.string,
    labelsTypes: PropTypes.string,
    xAxesLabel: PropTypes.string,
    yLabelType: PropTypes.string,
    subtext: PropTypes.string,
    ariaLabel: PropTypes.string
  })
};

// Data to pass in from JSON
// Items = array
// "label"
// "amount"
// Likely an optional "amount2"
// "xAxesLabel": "Percentage of respondents",
// "xLabelType": "percentage",
// "labelsTypes": "percentage", <--- Tool tips

ChartComponent.defaultProps = {
  data: {
    chartRow1: [],
    chartRow2: [],
    items: [
      {
        label: '',
        amount: 0
      }
    ],
    beginAtZero: false,
    solidColor: '',
    xLabelType: '',
    downloadUrl: '',
    shareUrl: '',
    fontFamily: '',
    chartType: '',
    labelsTypes: '',
    xAxesLabel: '',
    yLabelType: '',
    subtext: '',
    ariaLabel: ''
  }
};

export default globalGrowth2022Section;
