/* global $ */

export function eyeBrowStickySOTA2022(
  elementId,
  parentContainerId,
  underlineId,
  padding,
  endElementId,
  eyebrowBodyContainerId
) {
  let topPosition = padding;
  const originalPadding = padding;
  const parentContainer = $(parentContainerId);
  let isEyebrow = false;

  function drawStickyHeader() {
    isEyebrow = true;
    // $(elementId).css('width', parentContainer.width() - 2);
    // $(elementId).css('max-width', parentContainer.width() - 2);

    // Added this if, for SOTA 2021 design. Padding reset that was not needed on SOTA2020 design
    if (eyebrowBodyContainerId) {
      $(eyebrowBodyContainerId).css('padding-top', '0px');
    }

    $(elementId)
      .find('h2')
      .css('font-size', '16px')
      // .css('line-height', '32px')
      .css('padding-top', topPosition);
  }
  function scrollLogic() {
    // If we are above the sticky point (note: I added "- 50" to clear a bug on mobile, that was introduced from adding / removing padding based on if you were above or below the sticky point);
    if ($(window).scrollTop() < parentContainer.offset().top - 50) {
      $(underlineId).css('opacity', '0');
      $(underlineId).css('width', '0');

      // $(elementId).css('position', 'relative');
      // $(elementId).css('top', '0');

      $(elementId).css('max-width', parentContainer.width() - 2);
      $(elementId).css('border-top', 'none');
      // Added this "if" below, for SOTA 2021 design. Padding reset that was not needed on SOTA2020 design
      $(eyebrowBodyContainerId);
      if (eyebrowBodyContainerId) {
        $(eyebrowBodyContainerId).css('padding-top', '30px');
      }

      $(elementId).find('h2').css('font-size', '36px');
      $(elementId).find('h2').css('padding-top', '0px');
    }

    if ($(window).scrollTop() >= parentContainer.offset().top) {
      drawStickyHeader();
      // setTimeout time needs to be longer than title transition
      // To make sure the orangeLine grows to the right size
      if (isEyebrow) {
        setTimeout(() => {
          // if ($(elementId).css('position') === 'fixed') {
          if (isEyebrow) {
            $(underlineId).css('width', $(elementId).find('h2').width());
            $(underlineId).css('opacity', '1');

            // We add extra top to clear the Toc Button, the mobile solution for the left nav
            if ($(window).width() < 992) {
              topPosition = 118;
            } else {
              topPosition = originalPadding;
            }
            drawStickyHeader();
          }
        }, 350);
      }
    }

    // if (
    //   $(window).scrollTop() > $(endElementId).offset().top - 200 &&
    //   // $(endElementId).offset().top - $(elementId).height() - 65 &&
    //   $(elementId).css('position') === 'fixed'
    // ) {
    //   $(elementId).css('position', 'absolute');
    //   // 80 is the padding bottom from a parent container
    //   $(elementId).css(
    //     'top',
    //     // $(endElementId).offset().top - 200
    //     parentContainer.height() -
    //       $(elementId).height() -
    //       $(endElementId).height() -
    //       50
    //   );
    // }
  }

  $(window).on('scroll', () => {
    scrollLogic();
  });
  $(window).on('resize', () => {
    scrollLogic();
  });
}
// Below is for ToC mobile button on state-of-api 2021
export function stickyElementSOTA2022(
  elementId, // ToC ID
  startPoint, // Initial top value of ToC
  padding, // little bit of space above and below
  endElementId
) {
  $(window).on('scroll', () => {
    // This first if represents the initial position of the ToC button.
    // Remember, we may need to clear the hello (bar).  Need to figure out dynamic solution
    if ($(window).scrollTop() < startPoint - padding) {
      $(elementId).css('position', 'absolute');
      $(elementId).css('top', startPoint);
    }
    if (
      $(window).scrollTop() > startPoint - padding &&
      $(elementId).css('position') !== 'fixed'
    ) {
      $(elementId).css('position', 'fixed');
      $(elementId).css('top', startPoint);
    }

    if (
      $(window).scrollTop() >
      $(endElementId).offset().top - $(elementId).height()
    ) {
      $(elementId).css('position', 'absolute');
      // And make the button rest 24px above the footer
      $(elementId).css(
        'top',
        $(endElementId).offset().top + $(elementId).height() + 10
      );
    }
  });
}
