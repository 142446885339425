/* eslint-disable */
const customCharts = function() {
  // if (window.Chart) {
    if (document.location.pathname.indexOf('2018') > -1) {
      var graphColors = {
        mainBlue: '#1462c1',
        mainBlueBorder: '#10509e',
        mainTeal: '#0fb2d8',
        mainTealBorder: '#0c99ba',
        mainLightTeal: '#1ed3b9',
        mainLightTealBorder: '#18a994',
        mainGreen: '#21b721',
        mainGreenBorder: '#1a931a',
        mainLightGreen: '#8add3a',
        mainLightGreenBorder: '#6eb12e',
        mainYellow: '#ffe359',
        mainYellowBorder: '#ffb647',
        mainOrange: '#EF7D43',
        mainOrangeBorder: '#D26435',
        mainDarkOrange: '#ED623B',
        mainDarkOrangeBorder: '#CC4E2F',
        mainRed: '#DB4242',
        mainRedBorder: '#B03535'
      };
      function renderLegend(chart) {
        var text = [];
        text.push('<div class="chart-legend">');
        for (var i = 0; i < chart.data.datasets[0].data.length; i++) {
          text.push('<div class="d-flex align-items-center text-white">');
          text.push(
            '<span class="color-key text-white" style="background-color:' +
              chart.data.datasets[0].backgroundColor[i] +
              '"></span>'
          );
          if (chart.data.labels[i]) {
            text.push(
              '<span class="key-label text-white" style="color: white">' +
                chart.data.datasets[0].data[i] +
                '%' +
                ': ' +
                chart.data.labels[i] +
                '</span>'
            );
          }
          text.push('</div>');
        }
        text.push('</div>');
        return text.join('');
      }
      //Formats hover tooltip as "Label: Percentage"
      function percentageTooltips(tooltipItem, data) {
        return (
          data.labels[tooltipItem.index] +
          ': ' +
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] +
          '%'
        );
      }
      // Size of hole in doughnut chart. 0 - 100.
      var doughnutHoleSize = 60;

      // Who took the survey
      var survey2018_whoTookTheSurvey = document.getElementById(
        'survey2018_whoTookTheSurvey'
      );
      new Chart(survey2018_whoTookTheSurvey, {
        type: 'bar',
        data: {
          labels: [
            '0-3',
            '3-6',
            '6-9',
            '9-12',
            '12-15',
            '15-18',
            '18-21',
            '21-24',
            '24-27'
          ],
          datasets: [
            {
              data: [220, 90, 20, 30, 15, 10, 12, 2, 5],
              backgroundColor: [
                graphColors.mainBlue,
                graphColors.mainTeal,
                graphColors.mainLightTeal,
                graphColors.mainGreen,
                graphColors.mainLightGreen,
                graphColors.mainYellow,
                graphColors.mainOrange,
                graphColors.mainDarkOrange,
                graphColors.mainRed
              ],
              borderColor: [
                graphColors.mainBlueBorder,
                graphColors.mainTealBorder,
                graphColors.mainLightTealBorder,
                graphColors.mainGreenBorder,
                graphColors.mainLightGreenBorder,
                graphColors.mainYellowBorder,
                graphColors.mainOrangeBorder,
                graphColors.mainDarkOrangeBorder,
                graphColors.mainRedBorder
              ],
              borderWidth: 2
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            labels: {
              fontColor: '#fff'
            },
            align: 'start'
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'TOTAL COUNT',
                  fontColor: '#fff'
                },
                ticks: {
                  beginAtZero: true,
                  fontColor: '#fff'
                }
              }
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'YEARS OF EXPERIENCE',
                  fontColor: '#fff'
                },
                ticks: {
                  fontColor: '#fff'
                }
              }
            ]
          }
        }
      });
      // Hours spent with APIs
      var survey2018_hoursSpentWithAPIs = document.getElementById(
        'survey2018_hoursSpentWithAPIs'
      );
      document.getElementById(
        'legend_hoursSpentWithAPIs'
      ).innerHTML = new Chart(survey2018_hoursSpentWithAPIs, {
        type: 'doughnut',
        data: {
          labels: ['< 10 hours/week', '10-20 hours/week', '> 10 hours/week'],
          datasets: [
            {
              data: [35, 35, 30],
              backgroundColor: [
                graphColors.mainOrange,
                graphColors.mainGreen,
                graphColors.mainYellow
              ],
              borderWidth: 0
            }
          ]
        },
        options: {
          responsive: true,
          legend: {
            display: false,
            align: 'center',
            position: 'bottom',
            labels: {
              fontColor: '#fff'
            }
          },
          tooltips: {
            callbacks: {
              label: percentageTooltips
            }
          },
          cutoutPercentage: doughnutHoleSize,
          legendCallback: renderLegend
        }
      }).generateLegend();

      // Public vs Private
      var survey2018_publicVsPrivate = document.getElementById(
        'survey2018_publicVsPrivate'
      );
      document.getElementById('legend_publicVsPrivate').innerHTML = new Chart(
        survey2018_publicVsPrivate,
        {
          type: 'doughnut',
          data: {
            labels: [
              'Public APIs',
              'Internal Private APIs',
              'Partner Private APIs'
            ],
            datasets: [
              {
                data: [22, 52, 26],
                backgroundColor: [
                  graphColors.mainYellow,
                  graphColors.mainOrange,
                  graphColors.mainGreen
                ],
                borderWidth: 0
              }
            ]
          },
          options: {
            responsive: true,
            legend: {
              display: false,
              position: 'bottom',
              align: 'center',
              labels: {
                fontColor: '#fff'
              }
            },
            tooltips: {
              callbacks: {
                label: percentageTooltips
              }
            },
            cutoutPercentage: doughnutHoleSize,
            legendCallback: renderLegend
          }
        }
      ).generateLegend();
      // API Reliability survey2018_apiReliability
      var survey2018_apiReliability = document.getElementById(
        'survey2018_apiReliability'
      );
      document.getElementById('legend_apiReliability').innerHTML = new Chart(
        survey2018_apiReliability,
        {
          type: 'doughnut',
          data: {
            labels: [
              'Not often enough to matter',
              'Too frequently',
              'Daily',
              'Weekly',
              'Monthly'
            ],
            datasets: [
              {
                data: [50, 6, 3, 14, 27],
                backgroundColor: [
                  graphColors.mainOrange,
                  graphColors.mainYellow,
                  graphColors.mainBlue,
                  graphColors.mainGreen,
                  graphColors.mainTeal
                ],
                borderWidth: 0
              }
            ]
          },
          options: {
            responsive: true,
            legend: {
              display: false,
              position: 'bottom',
              labels: {
                fontColor: '#fff'
              }
            },
            tooltips: {
              callbacks: {
                label: percentageTooltips
              }
            },
            cutoutPercentage: doughnutHoleSize,
            legendCallback: renderLegend
          }
        }
      ).generateLegend();

      // width of category between 0 - 1.
      var categoryThickness = 0.4;
      // thickness of bars for two graphs below in relation to caregory size,
      //  must be between 0 - 1
      var barThickness = 0.5;

      var survey2018_apiSecurityAndDocumentation = document.getElementById(
        'survey2018_apiSecurityAndDocumentation'
      );
      document.getElementById(
        'legend_apiSecurityAndDocumentation'
      ).innerHTML = new Chart(survey2018_apiSecurityAndDocumentation, {
        type: 'bar',
        data: {
          labels: ['2017', '2018'],
          datasets: [
            {
              label: 'API Security',
              data: [2.5, 3.8],
              backgroundColor: [graphColors.mainOrange, graphColors.mainOrange],
              borderColor: [
                graphColors.mainOrangeBorder,
                graphColors.mainOrangeBorder
              ],
              borderWidth: 2
            },
            {
              label: 'API Documentation',
              data: [1.9, 2.3],
              backgroundColor: [graphColors.mainBlue, graphColors.mainBlue],
              borderColor: [
                graphColors.mainBlueBorder,
                graphColors.mainBlueBorder
              ],
              borderWidth: 2
            }
          ]
        },
        options: {
          responsive: true,
          legend: {
            display: false,
            labels: {
              fontColor: '#fff'
            }
          },
          legendCallback: function(chart) {
            var text = [];
            text.push('<div class="chart-legend d-flex">');
            for (var i = 0; i < chart.data.datasets[0].data.length; i++) {
              text.push('<div class="d-flex align-items-center text-white">');
              text.push(
                '<span class="color-key" style="background-color:' +
                  chart.data.datasets[i].backgroundColor[0] +
                  '"></span>'
              );
              if (chart.data.labels[i]) {
                // If it's the last label don't render with margin-right
                if (i === chart.data.labels.length - 1) {
                  text.push(
                    '<span class="key-label text-white" style="color: white">' +
                      chart.data.datasets[i].label +
                      '</span>'
                  );
                } else {
                  text.push(
                    '<span class="key-label mr-3" style="color: white">' +
                      chart.data.datasets[i].label +
                      '</span>'
                  );
                }
              }
              text.push('</div>');
            }
            text.push('</div>');
            return text.join('');
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'TOTAL COUNT',
                  fontColor: '#fff'
                },
                ticks: {
                  stepSize: 1,
                  beginAtZero: true,
                  fontColor: '#fff',
                  max: 5
                }
              }
            ],
            xAxes: [
              {
                barPercentage: barThickness,
                categoryPercentage: categoryThickness,
                ticks: {
                  fontColor: '#fff'
                }
              }
            ]
          }
        }
      }).generateLegend();
      //EXCITING TECHNOLOGY
      var survey2018_excitingTechnology = document.getElementById(
        'survey2018_excitingTechnology'
      );
      new Chart(survey2018_excitingTechnology, {
        type: 'horizontalBar',
        data: {
          labels: [
            'Microservices',
            'Containers',
            'Serverless Architecture',
            'HTTP 2.0',
            'GraphQL',
            'WebSockets',
            'Swagger 3.0',
            'Other'
          ],
          datasets: [
            {
              data: [49, 43.5, 39, 36, 30, 28, 24.75, 5.5],
              backgroundColor: [
                graphColors.mainBlue,
                graphColors.mainTeal,
                graphColors.mainLightTeal,
                graphColors.mainGreen,
                graphColors.mainLightGreen,
                graphColors.mainOrange,
                graphColors.mainDarkOrange,
                graphColors.mainRed
              ],
              borderColor: [
                graphColors.mainBlueBorder,
                graphColors.mainTealBorder,
                graphColors.mainLightTealBorder,
                graphColors.mainGreenBorder,
                graphColors.mainLightGreenBorder,
                graphColors.mainOrangeBorder,
                graphColors.mainDarkOrangeBorder,
                graphColors.mainRedBorder
              ],
              borderWidth: 2
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            labels: {
              fontColor: '#fff'
            }
          },
          tooltips: {
            callbacks: {
              label: percentageTooltips
            }
          },
          scales: {
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: 'Technology',
                  fontColor: '#fff'
                },
                ticks: {
                  stepSize: 1,
                  max: 10,
                  beginAtZero: true,
                  fontColor: '#fff'
                }
              }
            ],
            xAxes: [
              {
                display: true,
                barPercentage: barThickness,
                categoryPercentage: categoryThickness,
                ticks: {
                  max: 50,
                  stepValue: 10,
                  stepSize: 10,
                  fontColor: '#fff',
                  callback: function(value, index, values) {
                    return value + '%';
                  }
                }
              }
            ]
          }
        }
      });
      // SOURCES OF LEARNING
      var survey2018_sourcesOfLearning = document.getElementById(
        'survey2018_sourcesOfLearning'
      );
      new Chart(survey2018_sourcesOfLearning, {
        type: 'horizontalBar',
        data: {
          labels: [
            'On-the-job/Colleagues',
            'Published API Documentation',
            'Online Resources/Classes',
            'Online Communities',
            'In-person coding schools/camps',
            'Books on APIs',
            'Other'
          ],
          datasets: [
            {
              data: [72, 61, 58, 45, 17, 16, 4],
              backgroundColor: [
                graphColors.mainBlue,
                graphColors.mainTeal,
                graphColors.mainLightTeal,
                graphColors.mainGreen,
                graphColors.mainLightGreen,
                graphColors.mainOrange,
                graphColors.mainDarkOrange
              ],
              borderColor: [
                graphColors.mainBlueBorder,
                graphColors.mainTealBorder,
                graphColors.mainLightTealBorder,
                graphColors.mainGreenBorder,
                graphColors.mainLightGreenBorder,
                graphColors.mainOrangeBorder,
                graphColors.mainDarkOrangeBorder
              ],
              borderWidth: 2
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            labels: {
              fontColor: '#fff'
            }
          },
          tooltips: {
            callbacks: {
              label: percentageTooltips
            }
          },
          scales: {
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: 'Technology',
                  fontColor: '#fff'
                  // padding: 2
                },
                ticks: {
                  stepSize: 1,
                  max: 10,
                  beginAtZero: true,
                  fontColor: '#fff'
                }
              }
            ],
            xAxes: [
              {
                display: true,
                barPercentage: barThickness,
                categoryPercentage: categoryThickness,
                ticks: {
                  max: 80,
                  stepValue: 10,
                  stepSize: 10,
                  fontColor: '#fff',
                  callback: function(value, index, values) {
                    return value + '%';
                  }
                }
              }
            ]
          }
        }
      });

      var survey2018_howDevelopersPronounce = document.getElementById(
        'survey2018_howDevelopersPronounce'
      );
      document.getElementById(
        'legend_howDevelopersPronounce'
      ).innerHTML = new Chart(survey2018_howDevelopersPronounce, {
        type: 'doughnut',
        data: {
          labels: ['AAY-PEE-EYE', 'Other', 'Appie-rhymes with "Happy'],
          datasets: [
            {
              data: [82, 6, 12],
              backgroundColor: [
                graphColors.mainOrange,
                graphColors.mainGreen,
                graphColors.mainYellow
              ],
              borderWidth: 0
            }
          ]
        },
        options: {
          responsive: true,
          legend: {
            display: false,
            position: 'bottom',
            labels: {
              fontColor: '#fff'
            }
          },
          tooltips: {
            callbacks: {
              label: percentageTooltips
            }
          },
          cutoutPercentage: doughnutHoleSize,
          legendCallback: renderLegend
        }
      }).generateLegend();

      var survey2018_howFriendsAndFamilyPronounce = document.getElementById(
        'survey2018_howFriendsAndFamilyPronounce'
      );
      document.getElementById(
        'legend_howFriendsAndFamilyPronounce'
      ).innerHTML = new Chart(survey2018_howFriendsAndFamilyPronounce, {
        type: 'doughnut',
        data: {
          labels: [
            'Neither. They have no idea what an API is or what I really do.',
            "I don't have any non-technical friends/family",
            'Appie-rhymes with "Happy',
            'AAY-PEE-EYE'
          ],
          datasets: [
            {
              data: [63, 1, 9, 27],
              backgroundColor: [
                graphColors.mainOrange,
                graphColors.mainYellow,
                graphColors.mainGreen,
                graphColors.mainTeal
              ],
              borderWidth: 0
            }
          ]
        },
        options: {
          responsive: true,
          legend: {
            display: false,
            position: 'bottom',
            fullWidth: true,
            labels: {
              fontColor: '#fff'
            }
          },
          tooltips: {
            callbacks: {
              label: percentageTooltips
            }
          },
          cutoutPercentage: doughnutHoleSize,
          legendCallback: renderLegend
        }
      }).generateLegend();

      var survey2018_starTrekVsStarWars = document.getElementById(
        'survey2018_starTrekVsStarWars'
      );
      document.getElementById(
        'legend_starTrekVsStarWars'
      ).innerHTML = new Chart(survey2018_starTrekVsStarWars, {
        type: 'doughnut',
        data: {
          labels: [
            'All Star Trek',
            'Somewhat more Star Trek',
            'Somewhat more Star Wars',
            'All Star Wars'
          ],
          datasets: [
            {
              data: [10, 14, 21, 55],
              backgroundColor: [
                graphColors.mainYellow,
                graphColors.mainGreen,
                graphColors.mainTeal,
                graphColors.mainOrange
              ],
              borderWidth: 0
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
            position: 'right',
            labels: {
              fontColor: '#fff'
            }
          },
          tooltips: {
            callbacks: {
              label: percentageTooltips
            }
          },
          cutoutPercentage: doughnutHoleSize,
          legendCallback: renderLegend
        }
      }).generateLegend();
    }
  // }
};

export default customCharts;

/* eslint-enable */
