/* eslint-disable */
// handlers = window.handlers || {};

const customCharts = function() {
  // if (window.Chart) {
    if (document.location.pathname.indexOf('2019') > -1) {
      // ==========
      //  HELPERS
      // ==========
      var graphColors = {
        1: '#D15837',
        2: '#FF6C37',
        3: '#F79D79',
        4: '#493E5E',
        5: '#675D81',
        6: '#7D728E',
        7: '#FCB315',
        8: '#FFDB8A',
        9: '#1A4561',
        10: '#355265',
        11: '#6D7F91',
        12: '#2E368F',
        13: '#1462C1',
        14: '#0FB2D8',
        15: '#1ED3B9',
        16: '#21B721',
        17: '#8ADD3A',
        18: '#FAED23',
        19: '#CE962B',
        20: '#F15A26',
        21: '#EC2027',
        22: '#C1272D'
      };
      var graphTextColor = `${(props) => props.theme.colors.grey_90}`;
      //  Size of doughnut chart center, 0 - 100;
      var cutoutSize = 60;
      //  Creates HTML legend from chart data
      function renderLegend(chart) {
        var text = [];
        text.push('<div class="chart-legend">');
        for (var i = 0; i < chart.data.datasets[0].data.length; i++) {
          text.push('<div class="d-flex align-items-center">');
          text.push(
            '<span class="color-key" ' +
              chart.data.datasets[0].backgroundColor[i] +
              '"></span>'
          );
          if (chart.data.labels[i]) {
            text.push(
              '<span class="key-label">' +
                chart.data.datasets[0].data[i] +
                '%' +
                ': ' +
                chart.data.labels[i] +
                '</span>'
            );
          }
          text.push('</div>');
        }
        text.push('</div>');
        return text.join('');
      }
      // Create HTML legend for stacked bar graph legend
      function renderStackedBarLegend(chart) {
        var text = [];
        var legendItems = chart.legend.legendItems;
        text.push('<div class="chart-legend d-flex flex-column">');
        for (var i = 0; i < legendItems.length; i++) {
          text.push('<div class="d-flex center-text">');
          text.push(
            '<span class="color-key" ' +
              legendItems[i].fillStyle +
              '"></span>'
          );
          text.push(
            '<span class="key-label">' + legendItems[i].text + '</span>'
          );
          text.push('</div>');
        }
        text.push('</div>');
        return text.join('');
      }
      //  Formats hover tooltip as "Label: Percentage" - to nearest decimal
      function percentageTooltips(tooltipItem, data) {
        return (
          data.labels[tooltipItem.index] +
          ': ' +
          data.datasets[tooltipItem.datasetIndex].data[
            tooltipItem.index
          ].toFixed(1) +
          '%'
        );
      }
      // Returns an empty tooltip string to remove title
      function emptyTitle(item, data) {
        return;
      }
      // Make axes display % sign
      function percentageOnTicks(value, index, values) {
        return value + '%';
      }
      // ==========
      // GRAPHS
      // ==========
      //  WHO WORKS WITH APIS CHARTS
      var whoWorksWithApis_roles = document.getElementById(
        'whoWorksWithApis_roles'
      );
      new Chart(whoWorksWithApis_roles, {
        type: 'horizontalBar',
        data: {
          labels: [
            'Back-end developer',
            'Other',
            'QA engineer',
            'Technical Team Lead',
            'Front-end developer',
            'API Architect',
            'DevOps specialist',
            'Executive',
            'Developer Relations',
            'Technical Writer',
            'API Publisher'
          ],
          datasets: [
            {
              data: [
                36.45,
                15.43,
                13.8,
                11.83,
                10.15,
                3.91,
                3.55,
                2.44,
                1.29,
                0.76,
                0.38
              ],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5],
                graphColors[6],
                graphColors[7],
                graphColors[8],
                graphColors[9],
                graphColors[10],
                graphColors[11]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: 'Percentage of respondents',
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });

      var whoWorksWithApis_areas = document.getElementById(
        'whoWorksWithApis_areas'
      );
      new Chart(whoWorksWithApis_areas, {
        type: 'horizontalBar',
        data: {
          labels: [
            'Agile Development',
            'Microservices',
            'API/Technical Support',
            'Integration Testing',
            'Continuous Integration',
            'Functional Testing',
            'DevOps',
            'Test Automation',
            'API Distribution/Publishing',
            'Data Integration',
            'API Ops',
            'Mobile Development',
            'Cloud Management',
            'Developer Onboarding',
            'Partner Integration',
            'Developer Evangelism',
            'DataOps',
            'Other',
            'Contract Testing',
            'Sales/Marketing',
            'SecOps',
            'Game Development'
          ],
          datasets: [
            {
              data: [
                42.56,
                34.34,
                31.08,
                30.64,
                29.4,
                28.15,
                25.98,
                25.94,
                24.77,
                23.88,
                21.28,
                18.45,
                14.48,
                13.85,
                12.35,
                7.49,
                5.46,
                5.02,
                4.69,
                4.31,
                3.37,
                2.22
              ],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5],
                graphColors[6],
                graphColors[7],
                graphColors[8],
                graphColors[9],
                graphColors[10],
                graphColors[11],
                graphColors[12],
                graphColors[13],
                graphColors[14],
                graphColors[15],
                graphColors[16],
                graphColors[17],
                graphColors[18],
                graphColors[19],
                graphColors[20],
                graphColors[21],
                graphColors[22]
              ],
              borderWidth: 1
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: false,
                  labelString: '',
                  fontColor: graphTextColor
                },
                ticks: {
                  beginAtZero: true,
                  fontColor: graphTextColor
                }
              }
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  labelString: 'Percentage of respondents',
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });
      var whoWorksWithApis_industry = document.getElementById(
        'whoWorksWithApis_industry'
      );
      new Chart(whoWorksWithApis_industry, {
        type: 'horizontalBar',
        data: {
          labels: [
            'Technology',
            'Business/IT Services',
            'Banking/Finance',
            'Other',
            'Healthcare',
            'Retail',
            'Manufacturing',
            'Government/Defence',
            'Advertising/Agency',
            'Non-profit/Charitable organization'
          ],
          datasets: [
            {
              data: [
                52.32,
                41.18,
                15.41,
                11.57,
                8.62,
                8.36,
                5.01,
                4.84,
                4.79,
                3.66
              ],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5],
                graphColors[6],
                graphColors[7],
                graphColors[8],
                graphColors[9],
                graphColors[10]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });
      var whoWorksWithApis_yearsOfExperience = document.getElementById(
        'whoWorksWithApis_yearsOfExperience'
      );
      new Chart(whoWorksWithApis_yearsOfExperience, {
        type: 'horizontalBar',
        data: {
          labels: ['10+', '9', '8', '7', '6', '5', '4', '3', '2', '1', '0'],
          datasets: [
            {
              data: [
                12.2,
                0.6,
                2.6,
                2.5,
                3.8,
                12.5,
                6.8,
                13.6,
                18.4,
                19.1,
                7.7
              ],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5],
                graphColors[6],
                graphColors[7],
                graphColors[8],
                graphColors[9],
                graphColors[10],
                graphColors[11]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });

      var aDayInTheLife_teamStructures = document.getElementById(
        'aDayInTheLife_teamStructures'
      );
      document.getElementById(
        'aDayInTheLife_teamStructures-legend'
      ).innerHTML = new Chart(aDayInTheLife_teamStructures, {
        type: 'doughnut',
        data: {
          labels: ['1-2', '3-5', '6-10', '11-25', '26-50', '50+'],
          datasets: [
            {
              data: [8.1, 29.5, 35, 19.8, 5.9, 1.7],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5],
                graphColors[6]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          cutoutPercentage: cutoutSize,
          legend: {
            display: false,
            position: 'right',
            align: 'start',
            labels: {
              fontColor: graphTextColor
            }
          },
          legendCallback: renderLegend,
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          }
        }
      }).generateLegend();

      var aDayInTheLife_teamStructures_1 = document.getElementById(
        'aDayInTheLife_teamStructures_1'
      );
      new Chart(aDayInTheLife_teamStructures_1, {
        type: 'horizontalBar',
        data: {
          labels: ['Fewer than 10', '10-25', '26-50', '50+'],
          datasets: [
            {
              data: [27.2, 18.2, 12.0, 42.6],
              backgroundColor: [
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[1]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });

      var aDayInTheLife_timeSpent = document.getElementById(
        'aDayInTheLife_timeSpent'
      );
      document.getElementById(
        'aDayInTheLife_timeSpent-legend'
      ).innerHTML = new Chart(aDayInTheLife_timeSpent, {
        type: 'doughnut',
        data: {
          labels: [
            '> 20 hours/week',
            '< 10 hours a week',
            '10 - 20 hours/week'
          ],
          datasets: [
            {
              data: [26.98, 37.3, 35.72],
              backgroundColor: [graphColors[1], graphColors[2], graphColors[3]]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          cutoutPercentage: cutoutSize,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start',
            position: 'right'
          },
          legendCallback: renderLegend,
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          }
        }
      }).generateLegend();

      var aDayInTheLife_howTimeIsSpent = document.getElementById(
        'aDayInTheLife_howTimeIsSpent'
      );
      new Chart(aDayInTheLife_howTimeIsSpent, {
        type: 'bar',
        data: {
          labels: [
            'API Development',
            'Debugging & manual testing',
            'Automated testing',
            'Designing & mock APIs',
            'Managing others who use APIs',
            'API Documentation',
            'API Monitoring',
            'Publishing APIs',
            'Writing about APIs'
          ],
          datasets: [
            {
              data: [26.1, 22.2, 11.4, 11.2, 9.1, 7.3, 5.7, 3.6, 3.3],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5],
                graphColors[6],
                graphColors[7],
                graphColors[8],
                graphColors[9]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });

      var aDayInTheLife_howTimeShouldBeSpent = document.getElementById(
        'aDayInTheLife_howTimeShouldBeSpent'
      );
      new Chart(aDayInTheLife_howTimeShouldBeSpent, {
        type: 'bar',
        data: {
          labels: [
            'API Development',
            'Automated testing',
            'Designing & mocking APIs',
            'Debugging & manual testing',
            'Managing others who use APIs',
            'API Documentation',
            'API Monitoring',
            'Writing about APIs',
            'Publishing APIs'
          ],
          datasets: [
            {
              data: [27.5, 16.4, 13.3, 13.0, 9.1, 7.6, 5.5, 4.2, 3.4],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5],
                graphColors[6],
                graphColors[7],
                graphColors[8],
                graphColors[9]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });

      var aDayInTheLife_howApisAreLearned = document.getElementById(
        'aDayInTheLife_howApisAreLearned'
      );
      new Chart(aDayInTheLife_howApisAreLearned, {
        type: 'bar',
        data: {
          labels: [
            'On-thejob/colleagues',
            'Published API documentation',
            'Online resources/classes',
            'Online communities',
            'In-person coding schools',
            'Books on APIs',
            'Other'
          ],
          datasets: [
            {
              data: [71, 58, 58, 47, 17, 14, 2],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5],
                graphColors[6],
                graphColors[7]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              label: percentageTooltips,
              title: emptyTitle
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor
                }
              }
            ],
            yAxes: [
              {
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });

      var aDayInTheLife_numberOfApis = document.getElementById(
        'aDayInTheLife_numberOfApis'
      );
      document.getElementById(
        'aDayInTheLife_numberOfApis-legend'
      ).innerHTML = new Chart(aDayInTheLife_numberOfApis, {
        type: 'doughnut',
        data: {
          labels: ['1-5', '6-10', '11-20', '50+', '21-50', 'None'],
          datasets: [
            {
              data: [39.0, 21.7, 14.3, 13.0, 11.1, 0.1],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5],
                graphColors[6]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          cutoutPercentage: cutoutSize,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start',
            position: 'right'
          },
          tooltips: {
            callbacks: {
              label: percentageTooltips
            }
          },
          legendCallback: renderLegend
        }
      }).generateLegend();

      var performanceSecurity_internalExternal = document.getElementById(
        'performanceSecurity_internalExternal'
      );
      document.getElementById(
        'performanceSecurity_internalExternal-legend'
      ).innerHTML = new Chart(performanceSecurity_internalExternal, {
        type: 'doughnut',
        data: {
          labels: ['Internal', 'Partner', 'Public'],
          datasets: [
            {
              data: [52.8, 28.4, 18.8],
              backgroundColor: [graphColors[1], graphColors[2], graphColors[3]]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          cutoutPercentage: cutoutSize,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              label: percentageTooltips
            }
          },
          legendCallback: renderLegend
        }
      }).generateLegend();

      var performanceSecurity_performanceChange = document.getElementById(
        'performanceSecurity_performanceChange'
      );
      new Chart(performanceSecurity_performanceChange, {
        type: 'horizontalBar',
        data: {
          labels: [
            'Not often enough to matter',
            'Monthly',
            'Weekly',
            'Too frequently',
            'Daily'
          ],
          datasets: [
            {
              data: [49, 28, 16, 4, 3],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });

      var performanceSecurity_security = document.getElementById(
        'performanceSecurity_security'
      );
      new Chart(performanceSecurity_security, {
        type: 'horizontalBar',
        data: {
          labels: [
            'Above average security',
            'Very secure',
            'Average security',
            'Below average security',

            'Not at all secure'
          ],
          datasets: [
            {
              data: [38.7, 33.8, 16.1, 9.0, 2.4],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });

      var performanceSecurity_documentation = document.getElementById(
        'performanceSecurity_documentation'
      );
      new Chart(performanceSecurity_documentation, {
        type: 'horizontalBar',
        data: {
          labels: [
            'Documentation is below average',
            'Not well documented',
            'Documentation is above average',
            'Documentation is OK',
            'Very well documented'
          ],
          datasets: [
            {
              data: [34.5, 20.9, 17.8, 16.3, 10.4],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });

      var performanceSecurity_documentation_1 = document.getElementById(
        'performanceSecurity_documentation_1'
      );
      new Chart(performanceSecurity_documentation_1, {
        type: 'horizontalBar',
        data: {
          labels: [
            'Better examples',
            'Standardization',
            'Sample code',
            'Real-world use-cases',
            'Better workflow processes',
            'Additional tools',
            'SDKs',
            'Other'
          ],
          datasets: [
            {
              data: [63.48, 59.36, 57.82, 44.45, 41.28, 22.87, 19.81, 4.01],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5],
                graphColors[6],
                graphColors[7],
                graphColors[8]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });

      var performanceSecurity_excitingTechnologies = document.getElementById(
        'performanceSecurity_excitingTechnologies'
      );
      new Chart(performanceSecurity_excitingTechnologies, {
        type: 'horizontalBar',
        data: {
          labels: [
            'Microservices',
            'Containers',
            'Serverless Architecture',
            'OpenAPI/Swagger 3.0',
            'GraphQL',
            'HTTP 2.0',
            'WebSockets',
            'Other'
          ],
          datasets: [
            {
              data: [53.9, 45.5, 44.0, 37.3, 36.9, 36.1, 29.0, 3.6],
              backgroundColor: [
                graphColors[1],
                graphColors[2],
                graphColors[3],
                graphColors[4],
                graphColors[5],
                graphColors[6],
                graphColors[7],
                graphColors[8]
              ]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            callbacks: {
              title: emptyTitle,
              label: percentageTooltips
            }
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: false,
                  fontColor: graphTextColor
                },
                ticks: {
                  fontColor: graphTextColor,
                  callback: percentageOnTicks
                }
              }
            ]
          }
        }
      });

      var words_usage = document.getElementById('words_usage');
      document.getElementById('words_usage-legend').innerHTML = new Chart(
        words_usage,
        {
          type: 'horizontalBar',
          data: {
            labels: [
              'Deprecate',
              'Rubber ducking',
              'Happy path',
              'Nopping',
              'Copy pasta',
              'Reality 101 failure',
              'Yoda conditions',
              'Jimmy',
              'Egosurfing',
              'Bikeshedding',
              'Unicorny',
              'Stress puppy'
            ],
            datasets: [
              {
                label: 'Never heard of it',
                data: [
                  546,
                  4111,
                  2858,
                  6537,
                  2569,
                  6003,
                  5764,
                  7082,
                  6129,
                  6515,
                  5287,
                  5712
                ],
                backgroundColor: graphColors[1]
              },
              {
                label: 'Heard of it',
                data: [
                  1352,
                  2413,
                  1818,
                  1419,
                  2742,
                  1710,
                  1912,
                  1019,
                  1846,
                  1363,
                  2220,
                  1880
                ],
                backgroundColor: graphColors[7]
              },
              {
                label: 'Use it sometimes',
                data: [
                  5309,
                  1309,
                  2464,
                  471,
                  2188,
                  647,
                  683,
                  344,
                  489,
                  504,
                  858,
                  759
                ],
                backgroundColor: graphColors[5]
              },
              {
                label: 'Favorite',
                data: [
                  1925,
                  913,
                  1767,
                  132,
                  1387,
                  237,
                  286,
                  121,
                  132,
                  193,
                  284,
                  299
                ],
                backgroundColor: graphColors[9]
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: true,
            legend: {
              display: false,
              labels: {
                fontColor: graphTextColor
              },
              align: 'start'
            },
            legendCallback: renderStackedBarLegend,
            tooltips: {
              mode: 'index'
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: '# of responses'
                  },
                  stacked: true,
                  ticks: {
                    fontColor: graphTextColor
                    // callback: percentageOnTicks
                  }
                }
              ],
              yAxes: [{ stacked: true }]
            }
          }
        }
      ).generateLegend();

      var words_outsideOfSoftware = document.getElementById(
        'words_outsideOfSoftware'
      );
      document.getElementById(
        'words_outsideOfSoftware-legend'
      ).innerHTML = new Chart(words_outsideOfSoftware, {
        type: 'horizontalBar',
        data: {
          labels: [
            'Deprecate',
            'Rubber ducking',
            'Happy path',
            'Nopping',
            'Copy pasta',
            'Reality 101 failure',
            'Yoda conditions',
            'Jimmy',
            'Egosurfing',
            'Bikeshedding',
            'Unicorny',
            'Stress puppy'
          ],
          datasets: [
            {
              label: "I don't even know what this means!",
              data: [
                1024,
                4804,
                3278,
                6531,
                3231,
                6072,
                7070,
                6833,
                6191,
                6551,
                5616,
                5844
              ],
              backgroundColor: graphColors[1]
            },
            {
              label: 'Heard it used by non-technical people, but incorrectly',
              data: [
                1579,
                1566,
                1317,
                925,
                1439,
                1109,
                1170,
                725,
                1002,
                903,
                1162,
                1093
              ],
              backgroundColor: graphColors[7]
            },
            {
              label: "Heard it used correctly in the 'real world'",
              data: [
                4111,
                1299,
                2470,
                513,
                2309,
                755,
                694,
                423,
                805,
                568,
                1115,
                930
              ],
              backgroundColor: graphColors[5]
            },
            {
              label: "I've used it myself with non-technical friends/family",
              data: [
                2216,
                625,
                1482,
                185,
                1523,
                247,
                202,
                219,
                243,
                175,
                367,
                385
              ],
              backgroundColor: graphColors[9]
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legendCallback: renderStackedBarLegend,
          legend: {
            display: false,
            labels: {
              fontColor: graphTextColor
            },
            align: 'start'
          },
          tooltips: {
            mode: 'index'
          },
          scales: {
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: '# of responses'
                },
                stacked: true,
                ticks: {
                  fontColor: graphTextColor
                }
              }
            ],
            yAxes: [{ stacked: true }]
          }
        }
      }).generateLegend();
    }
  // }
};

export default customCharts;
