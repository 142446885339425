import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
/* eslint-enable */

import { ParagraphStyles } from 'aether-marketing';
import { addFixed } from '../../../utils/stickyElement';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

const Section = styled.div`
  position: relative;
`;

const SideWrap = styled.div`
  display: inline-flex;
  flex-direction: column;
  top: 0;
  margin-bottom: 32px;
`;

const SideLink = styled.a`
  display: inline-block;
  font-style: normal;
  font-family: ${(props) => props.theme.fonts.Inter};
  font-weight: 400;
  line-height: 18px;
  color: ${(props) => props.theme.colors.white};
  padding: 5px 10px;
  margin: 0px 0px 10px 0px;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  appearance: none;
  /* border: 1px solid transparent;
  text-transform: uppercase; */
  transition: ${(props) => props.theme.transitions.all};
  text-align: left;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    color: ${(props) => props.theme.colors.white};
    /* opacity: 0.7; */
    /* color: #f96f3f; */
    text-decoration: none;
  }
`;

const ActiveLink = styled(SideLink)`
  background-color: ${(props) => props.theme.colors.orange_50};

  &:hover {
    background-color: ${(props) => props.theme.colors.orange_50};
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
  }
`;

const Title = styled(ParagraphStyles)`
  padding: 0px 0px 0px 10px;
  font-size: 18px;
  font-family: ${(props) => props.theme.fonts.Inter};
  font-weight: 600;
`;

export class SidebarNavLinks extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data };
  }

  componentDidMount() {
    addFixed('#2020stateOfApiSideNav', '#SplitContainerLeftCol', 24, '#Footer');
  }

  render() {
    const { data } = this.state;

    const titleFontFamily = data.titleFontFamily ? data.titleFontFamily : '';
    const linkFontFamily = data.linkFontFamily ? data.linkFontFamily : '';
    const backgroundColor = data.backgroundColor ? data.backgroundColor : '';
    const padding = data.padding ? data.padding : '';
    const textAlign = data.textAlign
      ? data.textAlign
      : 'text-center text-lg-left';

    return (
      <SideWrap
        className={`${textAlign} w-100`}
        style={{ backgroundColor, padding }}
      >
        {data.sidetitle && (
          <Title
            className="bold text-white"
            style={{ fontFamily: titleFontFamily }}
          >
            {data.sidetitle}
          </Title>
        )}
        {data.leftLinks
          ? data.leftLinks.map((link) =>
              link.active ? (
                <ActiveLink
                  key={pmUuid()}
                  href={withPrefix(link.url)}
                  style={{ fontFamily: linkFontFamily }}
                  onClick={() => {
                    triggerGA(link.category, link.label);
                  }}
                >
                  {link.title}
                </ActiveLink>
              ) : (
                <SideLink
                  as="a"
                  key={pmUuid()}
                  href={withPrefix(link.url)}
                  style={{ fontFamily: linkFontFamily }}
                  onClick={() => {
                    triggerGA(link.category, link.label);
                  }}
                >
                  {link.title}
                </SideLink>
              )
            )
          : ''}
      </SideWrap>
    );
  }
}

const stickySideBar = (data) => (
  <Section id="2020stateOfApiSideNav">
    <SidebarNavLinks data={data} />
  </Section>
);

SidebarNavLinks.propTypes = {
  data: PropTypes.shape({
    titleFontFamily: PropTypes.string,
    linkFontFamily: PropTypes.string,
    backgroundColor: PropTypes.string,
    padding: PropTypes.string,
    textAlign: PropTypes.string
  }).isRequired
};

export default stickySideBar;
