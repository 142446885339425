import React from 'react';
import styled from 'styled-components';
import { BaseLinkStyles, UnorderedListStyles, OrderedListStyles } from 'aether-marketing';

const Section = styled.section`
  .alternative p {
    color: white;
  }
  a {
    ${BaseLinkStyles.componentStyle.rules}
  }
  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }
  ol {
    ${OrderedListStyles.componentStyle.rules}
  }
`;

const leftTitleWithText = (data) => {
  const elId = data.id !== undefined ? data.id : '';
  const colWidth = data.colWidth ? data.colWidth : 'col-lg-8 col-sm-12';

  // If padding specified in JSON.
  // div.container turned into container-fliud (100% with of <Section>)
  const padding = data.padding ? data.padding : '60px 0 60px 0';
  // const containerClass = data.padding ? 'container-fluid' : 'container';
  const containerClass = data.containerClass ? data.containerClass : 'container';

  const bodyContent = data.items ? data.items : [];

  return (
    <Section style={{ backgroundColor: data.backgroundColor, padding }}>
      <div className={`${containerClass}`} style={{ padding: data.containerPadding }}>
        <div className="row mb-3">
          {data && data.alternative ? (
            <div className={`${colWidth} text-left $`}>
              {data.title && (
                <h2 className="mb-4" id={elId}>
                  {data.title}
                </h2>
              )}
              <div className={`pb-3 ${data.alternative === true ? 'alternative' : ''}`} alternative={data.alternative.toString()} dangerouslySetInnerHTML={{ __html: bodyContent.join('') }} />
            </div>
          ) : (
            <div className={`${colWidth} text-left `}>
              {data.title && (
                <h2 className="mb-4" id={elId}>
                  {data.title}
                </h2>
              )}
              <div dangerouslySetInnerHTML={{ __html: bodyContent.join('') }} />
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default leftTitleWithText;
