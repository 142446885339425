import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import PropTypes from 'prop-types';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const CardWrapper = styled.div`
  margin-bottom: 32px;
`;

const TextWrapper = styled.div`
  p {
    margin-bottom: 0px;
    color: ${(props) => props.theme.colors.grey_00};
  }
`;

const imageStyles = {
  // maxHeight: "150px"
};

export function HorizontalThumbnail({ media, items }) {
  return (
    <CardWrapper className="row text-white">
      <div className="col-md-2 mb-3 mb-md-0 text-left">
        <img
          className="img-fluid"
          style={imageStyles}
          src={`${media.url}`}
          alt={`${media.alt}`}
          fetchpriority="low"
          loading="lazy"
        />
      </div>
      <TextWrapper className="col-md-9">
        <div dangerouslySetInnerHTML={{ __html: items.join('') }} />
      </TextWrapper>
    </CardWrapper>
  );
}

const horizontalThumbnailSection = (data) => (
  <div className="container">
    {data.items.map((item) => (
      <HorizontalThumbnail
        key={pmUuid()}
        media={item.media}
        items={item.items}
        alternative={data.alternative}
      />
    ))}
  </div>
);

HorizontalThumbnail.propTypes = {
  media: PropTypes.shape({
    url: PropTypes.string,
    alt: PropTypes.string
  }).isRequired,
  items: PropTypes.arrayOf(PropTypes.string)
};

HorizontalThumbnail.defaultProps = {
  items: []
};

export default horizontalThumbnailSection;
