import React from 'react';
import styled from 'styled-components';
import { Divider } from 'aether-marketing';
import LottiAnimationContainer from '../LottiAnimationContainer';

const HeroWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: 200px !important;
  margin-right: 0px !important;
  .img-container {
    padding-right: 0px !important;
  }
  @media (max-width: 992px) {
    margin-left: 0 !important;
    text-align: center;
  }
`;

const heroStateOfAPI2023 = ({ isAnimated = false, divider, media }) => {
  return (
    <>
      <HeroWrapper className="row justify-content-between mt-5 mt-lg-0">
        <h1 className="col-lg-3 align-self-center mb-3 mb-lg-0 mt-3 mt-lg-0">
          2023 State of the API Report
        </h1>
        <div className="col-lg-8 img-container">
          {isAnimated ? (
            <LottiAnimationContainer
              autoplay
              loop={false}
              animationFileName={media.lottieFileName}
            />
          ) : (
            <img
              className="img-fluid"
              alt="Postman State Of The API Report Postmanauts researching ontop of graphs. Illustration."
              src="https://voyager.postman.com/sota/2023/hero-sota-2023.svg"
            />
          )}
        </div>
      </HeroWrapper>
      {divider && <Divider />}
    </>
  );
};

export default heroStateOfAPI2023;
