import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import ImageContainer from '../subcomponents/ImageContainer';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const Section = styled.section`
  padding: 0px 0px 44px 0px;
`;

class KeyFindings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
      items: [
        {
          image: {
            src: 'https://voyager.postman.com/illustration/state-of-api-2022-spending-time-illustration.svg',
            alt: 'Postman State Of The API Report.  Key Findings 2. Illustration'
          },
          title: '1) Developers are spending most of their time on APIs',
          body: "Some 51% of respondents say that more than half of their organization's development effort is spent on APIs. That's up from 40% in 2020 and 49% last year, underscoring APIs' role as the building blocks of modern software."
        },
        {
          image: {
            src: 'https://voyager.postman.com/illustration/state-of-api-2022-api-investments-illustration.svg',
            alt: 'Postman State Of The API Report.  Key Findings 2. Illustration'
          },
          title:
            '2) API investments to remain strong, despite economic headwinds',
          body: 'Investments in APIs will increase or stay the same over the next 12 months, said 89% of global respondents. When polling just executives, a similar level of confidence emerged among 1,400 CEOs, CIOs, and CTOs. These forecasts come even as two out of three respondents indicated a negative view of the current economy.'
        },
        {
          image: {
            src: 'https://voyager.postman.com/illustration/state-of-api-2022-leaders-outperform-illustration.svg',
            alt: 'Postman State Of The API Report.  Key Findings 3. Illustration'
          },
          title: '3) API-first leaders outperform',
          body: 'While only 8% of respondents identified as API-first leaders, this small, elite group excelled on almost every metric. They produce APIs faster and have fewer failures. And they have brighter outlooks on employment and spending. When asked to evaluate API-first companies, more than three-quarters of all respondents agreed that developers at these companies are more productive, integrate with partners faster, and are happier.'
        },
        {
          image: {
            src: 'https://voyager.postman.com/illustration/state-of-api-2022-remote-work-illustration.svg',
            alt: 'Postman State Of The API Report.  Key Findings 4. Illustration'
          },
          title: '4) Remote work is "very important"',
          body: 'Much of the world views remote work as critical. In North America, 78% of developers and API professionals called it “very important,” exceeding the global figure of 72%. The findings come as many remote employees resist a return to the office.'
        },
        {
          image: {
            src: 'https://voyager.postman.com/illustration/state-of-api-2022-api-integration-illustration.svg',
            alt: 'Postman State Of The API Report.  Key Findings 5. Illustration'
          },
          title: '5) Internal API integration is paramount',
          body: "The number-one factor in deciding whether to consume and produce an API is how well it integrates with internal apps and systems. Last year, this consideration didn't even make the top three factors for API consumption. The shift suggests businesses are increasingly using APIs to share data and offer services internally."
        },
        {
          image: {
            src: 'https://voyager.postman.com/illustration/state-of-api-2022-design-skills-illustration.svg',
            alt: 'Postman State Of The API Report.  Key Findings 6. Illustration'
          },
          title: '6) Lack of API design skills a top problem',
          body: 'One of the top obstacles to producing APIs is new this year: a lack of API design skills. This skills gap may be contributing to an overproliferation of microservices, which is creating its own problems.'
        }
      ]
    };
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleExpand() {
    const { isCollapsed } = this.state;

    this.setState({
      isCollapsed: !isCollapsed
    });
  }

  render() {
    const {
      // isCollapsed,
      items
    } = this.state;
    // const collapseText = isCollapsed
    //   ? 'Learn how organizations used APIs to respond to the pandemic'
    //   : 'Collapse';
    // const collapseIcon = isCollapsed
    //   ? 'pm-icon-arrow-down'
    //   : 'pm-icon-arrow-up';
    return (
      <Section>
        <div className="container">
          <div className="row">
            {items.map((item) => {
              return (
                <div key={pmUuid()} className="col-12 col-lg-6">
                  <div style={{ padding: '30px' }}>
                    <ImageContainer
                      src={item.image.src}
                      title={item.image.alt}
                      height="190px"
                    />
                  </div>
                  <p className="">
                    <strong>{item.title}</strong>
                  </p>
                  <p>{item.body}</p>
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    );
  }
}

const keyFindingsTextCollapseStateOfAPI2022 = () => {
  return <KeyFindings />;
};

export default keyFindingsTextCollapseStateOfAPI2022;
