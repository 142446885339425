/* global $ */
import React from 'react';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import ChartDeferred from 'chartjs-plugin-deferred';
import ChartKey2023 from './ChartKey2023';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable import/no-extraneous-dependencies */

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, ChartDeferred);

function isMobileWidth() {
  if ($(window).width() <= 768) {
    return true;
  }
  return false;
}

const callbacks = {
  // hide(value) {
  //   return null;
  // },
  // Formats hover tooltip as "Label: Percentage" - to nearest decimal
  percentageTooltips(data) {
    const { dataset, dataIndex } = data;
    let { label } = data;
    label = label.replace(/,/g, ' ');

    return `${label}: ${dataset.data[dataIndex]}%`;
  },
  displayTitleTooltip(data) {
    let title = data[0].label;
    title = title.replace(/,/g, ' ');
    return title;
  },
  percentageTooltipsMultiDataset(data) {
    const { dataset, dataIndex } = data;
    return `${dataset.label}: ${dataset.data[dataIndex]}%`;
  },
  // For the numbers along the X or Y axes
  addCommasToLargeNumsOnAxes(chartJSValue) {
    if (typeof chartJSValue === 'number') {
      let value = chartJSValue; // because linter
      value = value.toString();
      value = value.split(/(?=(?:...)*$)/);
      value = value.join(',');
      return value;
    }
    return chartJSValue;
  },
  // Make axes display % sign (args: value, index, values)
  percentageOnTicks(value) {
    return `${value}%`;
  },
  // For the number values in the hoverable Tool tip, yes.. they are different functions :(
  addCommasToLargeNumsToolTip(tooltipItem) {
    const { label, formattedValue } = tooltipItem;
    return `${label}: ${formattedValue}`;
  }
};

export class SideBySideChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: null
    };
  }

  componentDidMount() {
    const { isMobile } = this.state;

    if (isMobile === null) {
      this.setState({
        isMobile: isMobileWidth()
      });
    }
    window.addEventListener('resize', () => {
      if (isMobileWidth()) {
        this.setState({
          isMobile: true
        });
      } else if (!isMobile) {
        this.setState({
          isMobile: false
        });
      }
    });
  }

  render() {
    const { isMobile } = this.state;
    const { data } = this.props;
    const { left, right } = data;

    const renderChart = (dataChart) => {
      const { chartType, chartData, options } = dataChart;
      // const backgroundColor = chartData.datasets[0].backgroundColor;
      // Show hide y axis labels
      if (isMobile) {
        options.scales.y.ticks.display = false;
      } else {
        options.scales.y.ticks.display = true;
      }

      // Adding the deferred plugin animation
      if (options.plugins) {
        options.plugins.deferred = {
          enabled: true,
          xOffset: 150,
          yOffset: '50%',
          delay: 200
        };
      } else {
        options.plugins = {
          deferred: {
            enabled: true,
            xOffset: 150,
            yOffset: '50%',
            delay: 200
          }
        };
      }

      // Note about below, be aware that chartJS option object keys can alternate between "callback" (singular) and "callbacks" (plural)
      if (options.scales?.x.ticks.callback && typeof options.scales?.x.ticks.callback === 'string') {
        options.scales.x.ticks.callback = callbacks[options.scales.x.ticks.callback];
      }
      if (options.scales?.y.ticks.callback && typeof options.scales?.y.ticks.callback === 'string') {
        options.scales.y.ticks.callback = callbacks[options.scales.y.ticks.callback];
      }
      if (options.plugins?.tooltip?.callbacks?.title && typeof options.plugins?.tooltip?.callbacks?.title !== 'function') {
        options.plugins.tooltip.callbacks.title = callbacks[options.plugins.tooltip.callbacks.title];
      } else if (typeof options.plugins?.tooltip?.callbacks?.title === 'undefined') {
        options.plugins.tooltip.callbacks.title = callbacks.hide;
      }
      if (options.plugins?.tooltip?.callbacks?.label && typeof options.plugins?.tooltip?.callbacks?.label === 'string') {
        options.plugins.tooltip.callbacks.label = callbacks[options.plugins.tooltip.callbacks.label];
      }

      let chartElement;
      if (chartType === 'doughnut') {
        chartElement = (
          <div role="img">
            <Doughnut data={chartData} height={300} options={options} key={pmUuid()} />
          </div>
        );
      } else {
        chartElement = (
          <div role="img">
            <Bar data={chartData} options={options} key={pmUuid()} />
          </div>
        );
      }

      return chartElement;
    };
    const leftChart = renderChart(left.chartData);
    const rightChart = renderChart(right.chartData);

    return (
      <div className="row">
        <div className="col-12 col-lg-5">
          {left.chartTitle && <p className="bold mb-4 ml-0 text-center">{left.chartTitle}</p>}
          {leftChart}
          <ChartKey2023 labels={left.chartData.chartData.labels} dataset={left.chartData.chartData.datasets[0].data} backgroundColor={left.chartData.chartData.datasets[0].backgroundColor} isPercent={false} className="mt-4" />
        </div>
        <div className="col-12 col-lg-5 offset-lg-2">
          {right.chartTitle && <p className="bold mb-4 ml-0 text-center">{right.chartTitle}</p>}
          {rightChart}
          <ChartKey2023 labels={right.chartData.chartData.labels} dataset={right.chartData.chartData.datasets[0].data} backgroundColor={right.chartData.chartData.datasets[0].backgroundColor} isPercent={false} className="mt-4" />
        </div>
      </div>
    );
  }
}

const chart2023SideBySideCharts = (data) => (
  <div className="container">
    <SideBySideChartComponent data={data} />
  </div>
);

SideBySideChartComponent.propTypes = {
  data: PropTypes.shape({
    left: PropTypes.shape({
      chartTitle: PropTypes.string,
      chartData: PropTypes.shape({
        chartData: PropTypes.shape({
          datasets: PropTypes.arrayOf(
            PropTypes.shape({
              data: PropTypes.arrayOf(PropTypes.number),
              backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
            })
          ),
          labels: PropTypes.arrayOf(PropTypes.string)
        }),
        options: PropTypes.shape({
          scales: PropTypes.shape({
            y: PropTypes.shape({
              ticks: PropTypes.shape({
                display: PropTypes.bool,
                callback: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
              })
            }),
            x: PropTypes.shape({
              ticks: PropTypes.shape({
                display: PropTypes.bool,
                callback: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
              })
            })
          }),
          plugins: PropTypes.shape({
            deferred: PropTypes.shape({
              enabled: PropTypes.bool,
              xOffset: PropTypes.number,
              yOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
              delay: PropTypes.number
            }),
            tooltip: PropTypes.shape({
              callbacks: PropTypes.shape({
                title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
                label: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
              })
            })
          })
        })
      })
    }),
    right: PropTypes.shape({
      chartTitle: PropTypes.string,
      chartData: PropTypes.shape({
        chartData: PropTypes.shape({
          datasets: PropTypes.arrayOf(
            PropTypes.shape({
              data: PropTypes.arrayOf(PropTypes.number),
              backgroundColor: PropTypes.string
            })
          ),
          labels: PropTypes.arrayOf(PropTypes.string)
        }),
        options: PropTypes.shape({
          scales: PropTypes.shape({
            y: PropTypes.shape({
              ticks: PropTypes.shape({
                display: PropTypes.bool,
                callback: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
              })
            }),
            x: PropTypes.shape({
              ticks: PropTypes.shape({
                display: PropTypes.bool,
                callback: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
              })
            })
          }),
          plugins: PropTypes.shape({
            deferred: PropTypes.shape({
              enabled: PropTypes.bool,
              xOffset: PropTypes.number,
              yOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
              delay: PropTypes.number
            }),
            tooltip: PropTypes.shape({
              callbacks: PropTypes.shape({
                title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
                label: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
              })
            })
          })
        })
      })
    })
  })
};

SideBySideChartComponent.defaultProps = {
  data: {
    left: {
      chartTitle: '',
      chartData: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: ''
            }
          ],
          labels: []
        },
        options: {
          scales: {
            y: {
              ticks: {
                display: true,
                callback: () => {}
              }
            },
            x: {
              ticks: {
                display: true,
                callback: () => {}
              }
            }
          },
          plugins: {
            deferred: {
              enabled: true,
              xOffset: 150,
              yOffset: '50%',
              delay: 200
            },
            tooltip: {
              callbacks: {
                title: () => {},
                label: () => {}
              }
            }
          }
        }
      }
    },
    right: {
      chartTitle: '',
      chartData: {
        chartData: {
          datasets: [
            {
              data: [],
              backgroundColor: ''
            }
          ],
          labels: []
        },
        options: {
          scales: {
            y: {
              ticks: {
                display: true,
                callback: () => {}
              }
            },
            x: {
              ticks: {
                display: true,
                callback: () => {}
              }
            }
          },
          plugins: {
            deferred: {
              enabled: true,
              xOffset: 150,
              yOffset: '50%',
              delay: 200
            },
            tooltip: {
              callbacks: {
                title: () => {},
                label: () => {}
              }
            }
          }
        }
      }
    }
  }
};

export default chart2023SideBySideCharts;
