import React from 'react';
import styled from 'styled-components';
import { Paragraph } from 'aether-marketing';
import customCharts from './handler';

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  .color-key {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
  p {
    color: white;
  }
  a {
    display: inline-block;
  }

  .v5_background__night-sky {
    background-color: ${(props) => props.theme.colors.night_sky};
  }
  .v5_background__deep-space-blue {
    background-color: ${(props) => props.theme.colors.grey_90};
  }

  section {
    padding: 60px;
  }
`;

class APISurvey extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/chart.js@2.8.0';
    script.async = true;
    document.body.appendChild(script);

    const script2 = document.createElement('script');
    script2.src = 'https://cdn.jsdelivr.net/npm/chartjs-plugin-deferred@1';
    script2.async = true;
    document.body.appendChild(script2);

    const script3 = document.createElement('script');
    script3.src =
      'https://cdn.jsdelivr.net/npm/chartjs-plugin-stacked100@0.7.1/src/index.min.js';
    script3.aync = true;
    document.body.appendChild(script3);

    // Check to make sure ChartJS is loaded on page before calling customCarts();
    let t;
    const checker = () => {
      if (window.Chart) {
        customCharts();
        clearTimeout(t);
      }
    };
    t = setTimeout(checker, 500);
  }

  render() {
    return (
      <Container>
        <section className="v5_section v5_background__night-sky">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <h2 className=" text-md-center text-lg-left text-white">
                  Who took the survey?
                </h2>
                <Paragraph className=" text-md-center text-white text-lg-left">
                  The survey respondents reflect the Postman community, which is
                  a pretty technical bunch, and a pretty API-focused bunch. The
                  vast majority are technical - developers, engineers, and
                  technical management, and most of them spend 10+ hours a week
                  with APIs. These are API power users, by any measure.
                </Paragraph>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <Paragraph className="subtitle text-white">
                  Who took the survey?
                </Paragraph>
                <div className="v5_mb-4">
                  <canvas id="survey2018_whoTookTheSurvey" height="475" />
                </div>
              </div>
              <div className="col-md-6 col-lg-4 text-center">
                <Paragraph className="subtitle text-white">
                  2018: Hours spent with APIs
                </Paragraph>
                <canvas id="survey2018_hoursSpentWithAPIs" height="250" />
                <div
                  id="legend_hoursSpentWithAPIs"
                  className="v5_mt-5 d-flex justify-content-center"
                />
                <Paragraph className="subtitle text-white v5_mt-3 text-center">
                  <strong>70%</strong>
                  of developers spend less than 20 hours/week with APIs
                </Paragraph>
              </div>
            </div>
          </div>
        </section>
        <section className="v5_section v5_background__deep-space-blue">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="h3 text-center text-white">
                  Public vs. Private
                </h2>
                <Paragraph className="text-white">
                  Our community uses all three types of APIs - Public, Private,
                  and Internal.
                  <strong>More than half</strong>
                  of all the APIs used are internal, private entities.
                </Paragraph>
                <canvas height="250" id="survey2018_publicVsPrivate" />
                <div
                  id="legend_publicVsPrivate"
                  className="v5_mt-5 v5_mb-5 d-flex justify-content-center"
                />
              </div>
              <div className="col-md-6">
                <h2 className="h3 text-center text-white">API Reliability</h2>
                <Paragraph className="text-white">
                  Developers are evenly split: about half think APIs don&apos;t
                  break often enough to matter, the rest think APIs break
                  regularly or too frequently
                </Paragraph>
                <canvas height="250" id="survey2018_apiReliability" />
                <div
                  id="legend_apiReliability"
                  className="v5_mt-5 d-flex justify-content-center"
                />
                <Paragraph className="subtitle text-white v5_mt-3 text-center">
                  <strong>50%</strong>
                  of developers are not bothered by APIs breaking
                </Paragraph>
              </div>
            </div>
          </div>
        </section>
        <section className="v5_section v5_background__night-sky">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="h3 text-center text-white">API Security</h2>
                <Paragraph className="text-white text-center">
                  Developers in 2018
                  <strong>even more confident</strong>
                  in API security.
                </Paragraph>
              </div>
              <div className="col-md-6">
                <h2 className="h3 text-center text-white">API Documentation</h2>
                <Paragraph className="text-white text-center">
                  Developers in 2018
                  <strong>even more confident</strong>
                  in API documentation.
                </Paragraph>
              </div>
            </div>
            <div className="row">
              <canvas id="survey2018_apiSecurityAndDocumentation" />
            </div>
            <div className="row">
              <div
                id="legend_apiSecurityAndDocumentation"
                className="v5_mt-5 mx-auto"
              />
            </div>
          </div>
        </section>
        <section className="v5_section v5_background__deep-space-blue">
          <div className="container">
            <div className="row">
              <div className="col-md-6 v5_mb-5">
                <h2 className="h3 text-white">Exciting Technology</h2>
                <Paragraph className="text-white">
                  Postman developers are most interested in working with
                  <strong>
                    microservice, containers, and serverless architecture
                  </strong>
                  technologies.
                </Paragraph>
              </div>
              <div className="col-md-6 m-0 p-0 v5_mb-5">
                <div>
                  <canvas
                    className="pt-2"
                    id="survey2018_excitingTechnology"
                    height="300"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h2 className="h3 text-white">Sources of Learning</h2>
                <Paragraph className="text-white">
                  Most of the developers in our community have learned a
                  majority of their API knowledge from their work on-the-job or
                  from other colleagues.
                </Paragraph>
              </div>
              <div className="col-md-6">
                <div>
                  <canvas id="survey2018_sourcesOfLearning" height="300" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="v5_section v5_background__night-sky">
          <div className="container">
            <div className="row">
              <h2 className="mx-auto h3 v5_mb-5 text-white">
                How do you pronounce &quot;API&quot;?
              </h2>
            </div>
            <div className="row v5_mb-3">
              <div className="col-md-6">
                <Paragraph className="subtitle text-white text-center">
                  Developers
                </Paragraph>
                <canvas height="250" id="survey2018_howDevelopersPronounce" />
                <div
                  id="legend_howDevelopersPronounce"
                  className="v5_mt-5 d-flex justify-content-center"
                />
                <Paragraph className="subtitle text-white v5_mt-3 text-center">
                  <strong>82%</strong>
                  of developers say &quot;AAY-PEE-EYE.&quot;
                </Paragraph>
              </div>
              <div className="col-md-6">
                <Paragraph className="subtitle text-white text-center">
                  Friends &amp; Family
                </Paragraph>
                <canvas
                  height="250"
                  id="survey2018_howFriendsAndFamilyPronounce"
                />
                <div
                  id="legend_howFriendsAndFamilyPronounce"
                  className="v5_mt-5 d-flex justify-content-center"
                />
                <Paragraph className="subtitle text-white v5_mt-3 text-center">
                  <strong>63%</strong>
                  of our family/friends have no idea.
                </Paragraph>
              </div>
            </div>
          </div>
        </section>
        <section className="v5_section v5_background__deep-space-blue">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-3 d-flex flex-column justify-content-center">
                <h2 className="h3 text-md-center text-white text-lg-left text-white">
                  Star Trek vs. Star Wars
                </h2>
                <Paragraph className="text-md-center text-white text-lg-left ">
                  The community has spoken and the dominating sci-fi universe is
                  with Star Wars. May the force be with you!
                </Paragraph>
              </div>
              <div className="col-12 col-md-6 col-lg-5 d-flex">
                <canvas id="survey2018_starTrekVsStarWars" />
              </div>
              <div className="col-12 col-md-6 col-lg-4 d-flex flex-column justify-content-center">
                <div id="legend_starTrekVsStarWars" className="v5_mt-5" />
                <Paragraph className="subtitle  v5_mt-3 text-white text-center">
                  <strong>76%</strong>
                  of respondents are more Star Wars fans than Star Trek.
                </Paragraph>
              </div>
            </div>
          </div>
        </section>
      </Container>
    );
  }
}

const apiSurvey2018 = (data) => (
  <div>
    <APISurvey data={data} />
  </div>
);

export default apiSurvey2018;
