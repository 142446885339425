import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import ModalButton from './MobileTocButtonModalButtonStateOfAPI2022';
import SidebarInModal from '../sidebars/sideBar';
// if there is a hello bar, this is where the ToC button sits.  This "clears" the Hello bar text
// This component is the ToC button container, The actual button is MobileTocButton

const Section = styled.div`
  position: fixed;
  left: 4.6rem;
  border-radius: 3px;
  text-align: left;

  button.nav-toggle-button {
    &:focus {
      outline: 0;
    }
    color: #212121;
    font-family: ${(props) => props.theme.fonts.Inter};
    font-weight: 600;
    border-radius: 3px;
    border-color: ${(props) => props.theme.colors.grey_90};
    background-color: #ffffff;
    padding: 13px 16px;
    width: auto;
    @media (max-width: 540px) {
      width: 100%;
    }
  }
`;

const ModalContent = styled.div`
  &.modal-content {
    &.video {
      background-color: transparent;
      border: 0;

      & .close {
        line-height: 1.9;
        opacity: 1;
        color: ${(props) => props.theme.colors.grey_90};
        font-weight: 400;
        text-shadow: none;
        margin-top: 60px;

        & .close-label {
          font-size: 0.75rem !important;
          margin-right: 0.25rem;
          vertical-align: middle;
        }

        & .close-icon {
          font-size: 19px;
        }

        &:hover {
          color: #ff6b37;
        }
      }
    }
  }
  &.modal-backdrop {
    &.show {
      opacity: 1;
    }
  }
`;

function setActiveLink(currentPath) {
  const linksArray = [
    {
      title: 'Key Findings',
      url: '/state-of-api/2022/#key-findings'
    },
    {
      title: 'Global Growth of APIs',
      url: '/state-of-api/2022/api-global-growth/#api-global-growth'
    },
    {
      title: 'Who Works with APIs',
      url: '/state-of-api/2022/who-works-with-apis/#who-works-with-apis'
    },
    {
      title: 'A Day, Week, or Year in the Life',
      url: '/state-of-api/2022/a-day-week-or-year-in-the-life/#a-day-week-or-year-in-the-life',
      active: true
    },
    {
      title: 'API-First and Other Strategies',
      url: '/state-of-api/2022/api-first-strategies/#api-first-strategies'
    },
    {
      title: 'API Leadership and Governance',
      url: '/state-of-api/2022/api-leadership-governance/#api-leadership-governance'
    },
    {
      title: 'Executing on APIs',
      url: '/state-of-api/2022/executing-on-apis/#executing-on-apis'
    },
    {
      title: 'Tooling for APIs and Development',
      url: '/state-of-api/2022/tooling-for-apis-and-development/#tooling-for-apis-and-development'
    },
    {
      title: 'API Technologies',
      url: '/state-of-api/2022/api-technologies/#api-technologies'
    },
    {
      title: 'APIs and the Future of Work',
      url: '/state-of-api/2022/future-of-work/#future-of-work'
    },
    {
      title: 'Methodology',
      url: '/state-of-api/2022/report-methodology/#report-methodology'
    },
    {
      title: 'How To Share the Report',
      url: '/state-of-api/2022/how-to-share-the-report/#how-to-share-the-report'
    },
    {
      title: 'Download the Report',
      url: 'https://voyager.postman.com/doc/postman-state-of-the-api-2022.pdf'
    }
  ];
  const linksArrayWithActive = linksArray.map((link) => {
    const linkPath = link.url.split('#')[0];
    // First we check if we are NOT on the "/state-of-api/" route, landing page
    if (
      (currentPath !== '/state-of-api/2022' ||
        currentPath !== '/state-of-api/2022/') &&
      link.url.includes(withPrefix(currentPath))
    ) {
      return {
        ...link,
        active: true
      };
    }
    // This second IF may look redundant, but we need a special conditional to explicitly check if we are on the landing page, because all routes include `/state-of-api/`
    if (
      linkPath === '/state-of-api/2022' &&
      currentPath === '/state-of-api/2022/'
    ) {
      return {
        ...link,
        active: true
      };
    }
    return {
      ...link
    };
  });

  return linksArrayWithActive;
}

class ModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      topVal: '59px',
      data,
      currentPath: ''
    };
  }

  componentDidMount() {
    const currentPath = window.location.pathname;
    this.setState(() => {
      return { currentPath };
    });
  }

  render() {
    const { data, topVal, currentPath } = this.state;
    return (
      <>
        <Section
          id="NavButton"
          className="d-inline d-lg-none"
          style={{ top: topVal, zIndex: 50 }}
        >
          <ModalButton data={data} endElementId={data.endElementId} />
        </Section>
        <div
          className="modal fade bd-example-modal-lg"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ backgroundColor: '#FFFFFF', zIndex: 2010 }}
        >
          <div className="modal-dialog modal-lg" role="document">
            <ModalContent className="modal-content video">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="close-label">CLOSE</span>
                  <span className="close-icon" aria-hidden="true">
                    X
                  </span>
                </button>
                <div style={{ paddingTop: '60px' }}>
                  {SidebarInModal({
                    type: 'sideBar',
                    leftSide: true,
                    className: 'text-left w-100',
                    sidetitle: 'Table of Contents',
                    leftLinks: setActiveLink(currentPath)
                  })}
                </div>
              </div>
            </ModalContent>
          </div>
        </div>
      </>
    );
  }
}

const stickyModalNavStateOfAPI2021 = (data) => <ModalWrapper data={data} />;

ModalWrapper.propTypes = {
  data: PropTypes.shape({
    toggleText: PropTypes.string,
    backgroundColor: PropTypes.string
  }).isRequired
};

export default stickyModalNavStateOfAPI2021;
