import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const LinkWrapper = styled.a`
  color: ${(props) => props.theme.colors.postman_orange};
  vertical-align: middle;
  font-weight: bold;
  &:hover {
    color: ${(props) => props.theme.colors.orange_50};
    text-decoration: none;
  }

  &:disabled,
  &.isDisabled,
  &:not([href]) {
    color: #556267;
    cursor: not-allowed;
  }
`;

function PrevNextPageButton(props) {
  const { text, iconType, url, isDisabled, className, fontFamily } = props;

  return (
    <LinkWrapper
      href={!isDisabled && url ? url : undefined}
      className={`${className}`}
      style={{ fontFamily }}
    >
      {iconType === 'left' && (
        <i className="mr-2 pm-icon-arrow-left align-middle" />
      )}
      {text}
      {iconType === 'right' && (
        <i className="ml-2 pm-icon-arrow-right align-middle" />
      )}
    </LinkWrapper>
  );
}

PrevNextPageButton.propTypes = {
  text: PropTypes.string,
  iconType: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  fontFamily: PropTypes.string
};

PrevNextPageButton.defaultProps = {
  text: '',
  isDisabled: false,
  className: '',
  fontFamily: ''
};

export default PrevNextPageButton;
