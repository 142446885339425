import React from 'react';
import styled from 'styled-components';
import LottiAnimationContainer from '../LottiAnimationContainer';

const Section = styled.section`
  padding: 60px 0px 0px 90px;
  @media (max-width: 992px) {
    padding: 60px 0px 0px 0px;
  }
  img {
    width: 98%;
  }
  background-color: #ffffff;
  max-width: 1440px;
  min-height: 485px;
  margin: auto;
`;

const heroStateOfAPI2022 = ({ isAnimated = false, media }) => {
  return (
    <Section>
      <div className="container">
        <div className="row">
          <div
            className="col-12 col-lg-4 align-items-center"
            style={{ paddingTop: '120px' }}
          >
            <div className="mb-4 mb-lg-0 mt-lg-5 pt-lg-5 text-center text-lg-left ">
              <h1>2022 State of the API Report</h1>
            </div>
          </div>
          <div className="col-12 col-lg-8" style={{ minHeight: '549px' }}>
            {isAnimated ? (
              <LottiAnimationContainer
                autoplay
                loop={false}
                animationFileName={media.lottieFileName}
              />
            ) : (
              <img
                alt="Postman State Of The API Report Postmanauts researching ontop of graphs. Illustration."
                src="https://voyager.postman.com/illustration/state-of-api-2022-hero-illustration.svg"
              />
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default heroStateOfAPI2022;
