import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
// import { BaseButton } from 'aether-marketing';
import stickyElement from '../../../../utils/stickyElement';

// if there is a hello bar, this is where the ToC button sits.  This "clears" the Hello bar text
const topValIfHello = 150;

const Icon = styled.i`
  font-size: 9px;
`;
const BaseButton = styled.div``;

function turnOnModal() {
  document.querySelectorAll('.nav-toggle-button').forEach((btn) => {
    btn.addEventListener('click', () => {
      const modal = document.querySelector('#myModal');
      modal.style.display = 'block';
      modal.style.paddingRight = '17px';
      modal.className = 'modal fade show';
    });
  });
}

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data };
  }

  componentDidMount() {
    let hello;
    const { setTopVal } = this.props;
    setTimeout(() => {
      hello = document.getElementById('message-bar-alert-top');
      if (hello !== null) {
        setTopVal();
        stickyElement('#NavButton', topValIfHello, 24, '#PrevNextButtonSection');
      } else {
        stickyElement('#NavButton', 55, 24, '#PrevNextButtonSection');
      }
    }, 1000);
  }

  render() {
    const { data } = this.state;
    return (
      <BaseButton className="mr-0">
        <button
          onClick={() => {
            turnOnModal();
          }}
          className="nav-toggle-button"
          type="button"
          data-toggle="modal"
          data-target="#myModal"
        >
          {data.toggleText}
          <Icon className="ml-3 pm-icon-arrow-down align-middle" />
        </button>

        {data.links ? (
          <BaseButton className="mb-4" target="_blank" rel="noopener noreferrer" src={data.links.src}>
            {data.links.text}
          </BaseButton>
        ) : (
          ''
        )}
      </BaseButton>
    );
  }
}

Modal.propTypes = {
  data: PropTypes.shape({
    toggleText: PropTypes.string,
    backgroundColor: PropTypes.string
  }).isRequired,
  setTopVal: PropTypes.func.isRequired
};

// stickyModalNav.propTypes = {
//   data: PropTypes.shape({}),
// };

// stickyModalNav.defaultProps = {
//   data: {},
// };
