/* global $ */
import React from 'react';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Tooltip } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { ParagraphStyles } from 'aether-marketing';
import ChartDeferred from 'chartjs-plugin-deferred';
import styled from 'styled-components';
import ChartKey2023 from './ChartKey2023';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable import/no-extraneous-dependencies */

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, ChartDeferred);

const SubtextStyles = styled(ParagraphStyles)`
  font-weight: 600;
  font-size: 10px !important;
  color: #a6a6a6;
  font-style: italic;
`;

function isMobileWidth() {
  if ($(window).width() <= 768) {
    return true;
  }
  return false;
}

const callbacks = {
  hide() {
    return null;
  },
  // Formats hover tooltip as "Label: Percentage" - to nearest decimal
  percentageTooltips(data) {
    const { dataset, dataIndex } = data;
    let { label } = data;
    label = label.replace(/,/g, ' ');

    return `${label}: ${dataset.data[dataIndex]}%`;
  },
  displayTitleTooltip(data) {
    let title = data[0].label;
    title = title.replace(/,/g, ' ');
    return title;
  },
  percentageTooltipsMultiDataset(data) {
    const { dataset, dataIndex } = data;
    return `${dataset.label}: ${dataset.data[dataIndex]}%`;
  },
  // For the numbers along the X or Y axes
  addCommasToLargeNumsOnAxes(chartJSValue) {
    if (typeof chartJSValue === 'number') {
      let value = chartJSValue; // because linter
      value = value.toString();
      value = value.split(/(?=(?:...)*$)/);
      value = value.join(',');
      return value;
    }
    return chartJSValue;
  },
  // Make axes display % sign (args: value, index, values)
  percentageOnTicks(value) {
    return `${value}%`;
  },
  // For the number values in the hoverable Tool tip, yes.. they are different functions :(
  addCommasToLargeNumsToolTip(tooltipItem) {
    const { label, formattedValue } = tooltipItem;
    return `${label}: ${formattedValue}`;
  }
};

export class ChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: null
    };
  }

  componentDidMount() {
    const { isMobile } = this.state;

    if (isMobile === null) {
      this.setState({
        isMobile: isMobileWidth()
      });
    }
    window.addEventListener('resize', () => {
      if (isMobileWidth()) {
        this.setState({
          isMobile: true
        });
      } else if (!isMobile) {
        this.setState({
          isMobile: false
        });
      }
    });
  }

  render() {
    const { isMobile } = this.state;
    const { data } = this.props;

    const { chartType, chartData, options, keyFontColor, keyIsPercent = true } = data;
    const { backgroundColor } = chartData.datasets[0];
    // Ensure options.scales is defined and has default values
    options.scales = {
      x: {
        ticks: {
          callback: null,
          ...options.scales?.x?.ticks
        },
        ...options.scales?.x
      },
      y: {
        ticks: {
          callback: null,
          ...options.scales?.y?.ticks
        },
        ...options.scales?.y
      },
      ...options.scales
    };
    // Show hide y axis labels
    if (isMobile) {
      options.scales.y.ticks.display = false;
    } else {
      options.scales.y.ticks.display = true;
    }

    // Adding the deferred plugin animation
    if (options.plugins) {
      options.plugins.deferred = {
        enabled: true,
        xOffset: 150,
        yOffset: '50%',
        delay: 200,
        ...options.plugins.deferred
      };
    } else {
      options.plugins = {
        deferred: {
          enabled: true,
          xOffset: 150,
          yOffset: '50%',
          delay: 200
        }
      };
    }

    // Note about below, be aware that chartJS option object keys can alternate between "callback" (singular) and "callbacks" (plural)
    /* eslint-disable valid-typeof */
    if (options.scales?.x?.ticks?.callback && typeof options.scales.x.ticks.callback === 'string') {
      options.scales.x.ticks.callback = callbacks[options.scales.x.ticks.callback];
    }
    if (options.scales?.y?.ticks?.callback && typeof options.scales.y.ticks.callback === 'string') {
      options.scales.y.ticks.callback = callbacks[options.scales.y.ticks.callback];
    }
    if (options.plugins?.tooltip?.callbacks?.title && typeof options.plugins?.tooltip?.callbacks?.title !== 'function') {
      options.plugins.tooltip.callbacks.title = callbacks[options.plugins.tooltip.callbacks.title];
    } else if (typeof options.plugins?.tooltip?.callbacks?.title === undefined) {
      options.plugins.tooltip.callbacks.title = callbacks.hide;
    }
    if (options.plugins?.tooltip?.callbacks?.label && typeof options.plugins?.tooltip?.callbacks?.label === 'string') {
      options.plugins.tooltip.callbacks.label = callbacks[options.plugins.tooltip.callbacks.label];
    }
    /* eslint-enable valid-typeof */

    let chartElement;
    if (chartType === 'doughnut') {
      chartElement = (
        <div role="img" aria-label={data.ariaLabel && data.ariaLabel}>
          <Doughnut data={chartData} height={300} options={options} key={pmUuid()} />
        </div>
      );
    } else {
      chartElement = (
        <div role="img" aria-label={data.ariaLabel && data.ariaLabel}>
          <Bar data={chartData} options={options} key={pmUuid()} />
        </div>
      );
    }

    let chartSection;
    if (chartType === 'doughnut') {
      chartSection = (
        <div className="row" style={{ paddingLeft: '14px' }}>
          <div className="col-12 col-md-7">{chartElement}</div>
          <div className="col-12 text-center text-md-left col-md-5 mb-4 mb-md-0 pr-0 pl-0">
            <ChartKey2023 fontColor={keyFontColor && keyFontColor} labels={chartData.labels} dataset={chartData.datasets[0].data} backgroundColor={backgroundColor} isPercent={keyIsPercent || null} />
          </div>
        </div>
      );
    } else if (chartData.datasets.length > 1) {
      const multiDatasetLabels = chartData.datasets.map((dataset) => dataset.label);
      const multiDatasetBackgroundColor = chartData.datasets.map((dataset) => dataset.backgroundColor);
      chartSection = (
        <div className="row" style={{ paddingLeft: '16px' }}>
          <div className="col-12 mb-4">{chartElement}</div>
          <div className="col-12 text-center mb-4 mb-md-1">
            <ChartKey2023 fontColor={keyFontColor && keyFontColor} labels={multiDatasetLabels} dataset={chartData.datasets[0].data} backgroundColor={multiDatasetBackgroundColor} isPercent={keyIsPercent || null} displayLabelOnlyAndOmitValues />
          </div>
        </div>
      );
    } else {
      chartSection = (
        <div className="row" style={{ paddingLeft: '16px' }}>
          <div className="col-12">{chartElement}</div>
          <div className="col-12 text-center mb-4 mb-md-1 d-md-none pt-4 pt-md-0">
            <ChartKey2023 fontColor={keyFontColor && keyFontColor} labels={chartData.labels} dataset={chartData.datasets[0].data} backgroundColor={[]} isPercent={keyIsPercent || null} />
          </div>
        </div>
      );
    }

    const subtext = data.subtext ? data.subtext : '';
    return (
      <>
        {chartSection}
        <div className="row" style={{ marginBottom: '30px' }}>
          {subtext && (
            <div className="col-12 mt-4">
              <SubtextStyles className="mb-0 text-center">{subtext}</SubtextStyles>
            </div>
          )}
        </div>
      </>
    );
  }
}

const barChart2022 = (data) => (
  <div className="container">
    <ChartComponent data={data} />
  </div>
);

ChartComponent.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        amount: PropTypes.number
      })
    ),
    chartData: PropTypes.shape({
      labels: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
      datasets: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          data: PropTypes.arrayOf(PropTypes.number),
          backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
        })
      )
    }),
    options: PropTypes.shape({
      responsive: PropTypes.bool,
      scales: PropTypes.shape({
        y: PropTypes.shape({
          ticks: PropTypes.shape({
            display: PropTypes.bool,
            callback: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
          })
        }),
        x: PropTypes.shape({
          ticks: PropTypes.shape({
            display: PropTypes.bool,
            callback: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
          })
        })
      }),
      plugins: PropTypes.shape({
        deferred: PropTypes.shape({
          enabled: PropTypes.bool,
          xOffset: PropTypes.number,
          yOffset: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          delay: PropTypes.number
        }),
        tooltip: PropTypes.shape({
          callbacks: PropTypes.shape({
            title: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
            label: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
          })
        })
      })
    }),
    keyFontColor: PropTypes.string,
    keyIsPercent: PropTypes.bool,
    beginAtZero: PropTypes.bool,
    solidColor: PropTypes.string,
    xLabelType: PropTypes.string,
    downloadUrl: PropTypes.string,
    shareUrl: PropTypes.string,
    fontFamily: PropTypes.string,
    chartType: PropTypes.string,
    labelsTypes: PropTypes.string,
    xAxesLabel: PropTypes.string,
    yLabelType: PropTypes.string,
    subtext: PropTypes.string,
    ariaLabel: PropTypes.string
  })
};

// Data to pass in from JSON
// Items = array
// "label"
// "amount"
// Likely an optional "amount2"
// "xAxesLabel": "Percentage of respondents",
// "xLabelType": "percentage",
// "labelsTypes": "percentage", <--- Tool tips

ChartComponent.defaultProps = {
  data: {
    items: [],
    chartData: {
      labels: [],
      datasets: [
        {
          label: '',
          data: [],
          backgroundColor: ''
        }
      ]
    },
    options: {
      responsive: false,
      scales: {},
      plugins: {}
    },
    keyFontColor: '',
    keyIsPercent: false,
    beginAtZero: false,
    solidColor: '',
    xLabelType: '',
    downloadUrl: '',
    shareUrl: '',
    fontFamily: '',
    chartType: '',
    labelsTypes: '',
    xAxesLabel: '',
    yLabelType: '',
    subtext: '',
    ariaLabel: ''
  }
};

export default barChart2022;
