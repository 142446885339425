import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
/* eslint-enable */

import { ParagraphStyles, BaseButton } from 'aether-marketing';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */
// import { addFixed } from '../../../utils/stickyElement';

// This is the modal content displayed from mobile toc
const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

const Section = styled.div`
  position: relative;
`;

const SideWrap = styled.div`
  display: inline-flex;
  flex-direction: column;
  top: 0;
  margin-bottom: 32px;
`;

const SideLink = styled(BaseButton)`
  display: inline-block;
  font-family: ${(props) => props.theme.fonts.Inter};
  font-weight: 400;
  /* line-height: 18px; */
  color: #212121 !important;
  padding: 0px 10px;
  margin: 0px 0px 10px 0px;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  appearance: none;
  transition: 0.2s ease-in-out;

  &:hover {
    background: #fff1eb;
    color: #212121;
    text-decoration: none;
  }
`;

const ActiveLink = styled(SideLink)`
  background: #fff1eb;

  &:hover {
    background: #fff1eb;
    text-decoration: none;
  }
`;

const Title = styled(ParagraphStyles)`
  color: #212121;
  font-family: 'degular_displaysemibold', system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica, Arial, sans-serif !important;
  font-weight: 600 !important;
  color: #212121;
  padding: 60px 0px 0px 10px;
  font-size: 24px;
`;

export class SidebarNavLinksStateOfAPI2021 extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data, currentPath: '' };
  }

  componentDidMount() {
    // addFixed('#2020stateOfApiSideNav', '#SplitContainerLeftCol', 24, '#Footer');
    const currentPath = window.location.pathname;
    this.setState(() => {
      return { currentPath };
    });
  }

  render() {
    const { data, currentPath } = this.state;

    const linkFontFamily = data.linkFontFamily ? data.linkFontFamily : '';
    const backgroundColor = data.backgroundColor ? data.backgroundColor : '';
    const padding = data.padding ? data.padding : '';
    const textAlign = data.textAlign
      ? data.textAlign
      : 'text-center text-lg-left';
    const ArrayOfLinkStates = data.leftLinks.map((link) => {
      const linkPath = link.url.split('#')[0];
      if (currentPath !== '/state-of-api/' && link.url.includes(currentPath)) {
        return (
          <ActiveLink
            key={pmUuid()}
            href={withPrefix(link.url)}
            style={{ fontFamily: linkFontFamily }}
            onClick={() => {
              triggerGA(link.category, link.label);
            }}
          >
            {link.title}
          </ActiveLink>
        );
      }
      if (linkPath === '/state-of-api/' && currentPath === '/state-of-api/') {
        return (
          <ActiveLink
            key={pmUuid()}
            href={withPrefix(link.url)}
            style={{ fontFamily: linkFontFamily }}
            onClick={() => {
              triggerGA(link.category, link.label);
            }}
          >
            {link.title}
          </ActiveLink>
        );
      }
      return (
        <SideLink
          key={pmUuid()}
          href={withPrefix(link.url)}
          style={{ fontFamily: linkFontFamily }}
          onClick={() => {
            triggerGA(link.category, link.label);
          }}
        >
          {link.title}
        </SideLink>
      );
    });

    return (
      <>
        <Title>TABLE OF CONTENTS</Title>
        <SideWrap
          className={`${textAlign} w-100`}
          style={{ backgroundColor, padding }}
        >
          {ArrayOfLinkStates}
        </SideWrap>
      </>
    );
  }
}

const stickySideBar = (data) => (
  <Section id="2021stateOfApiSideNav">
    <SidebarNavLinksStateOfAPI2021 data={data} />
  </Section>
);

SidebarNavLinksStateOfAPI2021.propTypes = {
  data: PropTypes.shape({
    titleFontFamily: PropTypes.string,
    linkFontFamily: PropTypes.string,
    backgroundColor: PropTypes.string,
    padding: PropTypes.string,
    textAlign: PropTypes.string
  }).isRequired
};

export default stickySideBar;
