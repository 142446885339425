import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
/* eslint-enable */

import { ParagraphStyles } from 'aether-marketing';
import sideBarLinkData from './sideBarLinkData.json';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};

const Section = styled.div`
  position: relative;
`;

const SideWrap = styled.div`
  display: inline-flex;
  flex-direction: column;
  top: 0;
  margin-bottom: 32px;
`;

const SideLink = styled.a`
  display: inline-block;
  font-family: ${(props) => props.theme.fonts.Inter} !important;
  font-weight: 400;
  color: #212121 !important;
  padding: 0px 10px;
  margin: 0px 24px 10px 0;
  background: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none;
  appearance: none;
  transition: 0.2s ease-in-out;
  text-align: left;

  &:hover {
    background: #fff1eb;
    color: #212121;
    text-decoration: none;
  }
`;

const ActiveLink = styled(SideLink)`
  background: #fff1eb;

  &:hover {
    background: #fff1eb;
    text-decoration: none;
  }
`;

const Title = styled(ParagraphStyles)`
  color: #212121;
  font-family: 'degular_displaysemibold', system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica, Arial, sans-serif !important;
  font-weight: 600 !important;
  color: #212121;
  padding: 0px 0px 0px 10px;
  font-size: 24px;
`;

export class SidebarNavLinks extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data };
  }

  componentDidMount() {
    const currentPath = window.location.pathname;
    this.setState(() => {
      return { currentPath };
    });
  }

  render() {
    const { data, currentPath } = this.state;
    const titleFontFamily = data.titleFontFamily ? data.titleFontFamily : '';
    const linkFontFamily = data.linkFontFamily ? data.linkFontFamily : '';
    const backgroundColor = data.backgroundColor
      ? data.backgroundColor
      : '#fff';
    const padding = data.padding ? data.padding : '';
    const textAlign = data.textAlign
      ? data.textAlign
      : 'text-center text-lg-left';

    const yearPathNoTrailingSlashWithPrefix = withPrefix('/2021');

    const ArrayOfLinkStates = data.items.map((link) => {
      const linkUrlWithPrefix = withPrefix(link.url);
      if (
        (currentPath === `${yearPathNoTrailingSlashWithPrefix}/` ||
          currentPath === `yearPathNoTrailingSlashWithPrefix`) &&
        linkUrlWithPrefix ===
          `${yearPathNoTrailingSlashWithPrefix}/#key-findings`
      ) {
        return (
          <ActiveLink
            key={pmUuid()}
            href={linkUrlWithPrefix}
            style={{ fontFamily: linkFontFamily }}
            onClick={() => {
              triggerGA(link.category, link.label);
            }}
          >
            {link.title}
          </ActiveLink>
        );
      }
      if (
        linkUrlWithPrefix.includes(currentPath) &&
        currentPath !== yearPathNoTrailingSlashWithPrefix &&
        currentPath !== `${yearPathNoTrailingSlashWithPrefix}/`
      ) {
        return (
          <ActiveLink
            key={pmUuid()}
            href={linkUrlWithPrefix}
            style={{ fontFamily: linkFontFamily }}
            onClick={() => {
              triggerGA(link.category, link.label);
            }}
          >
            {link.title}
          </ActiveLink>
        );
      }
      return (
        <SideLink
          key={pmUuid()}
          href={linkUrlWithPrefix}
          style={{ fontFamily: linkFontFamily }}
          onClick={() => {
            triggerGA(link.category, link.label);
          }}
        >
          {link.title}
        </SideLink>
      );
    });
    return (
      <SideWrap
        className={`${textAlign} w-100`}
        style={{ backgroundColor, padding }}
      >
        {data.sidetitle && (
          <Title className="bold" style={{ fontFamily: titleFontFamily }}>
            {data.sidetitle}
          </Title>
        )}
        {ArrayOfLinkStates}
      </SideWrap>
    );
  }
}

const sideBarStateOfApi2021 = () => (
  <Section id="2021stateOfApiSideNav">
    <SidebarNavLinks data={sideBarLinkData} />
  </Section>
);

SidebarNavLinks.propTypes = {
  data: PropTypes.shape({
    titleFontFamily: PropTypes.string,
    linkFontFamily: PropTypes.string,
    backgroundColor: PropTypes.string,
    padding: PropTypes.string,
    textAlign: PropTypes.string
  }).isRequired
};

export default sideBarStateOfApi2021;
