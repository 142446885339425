import React from 'react';
import { withPrefix } from 'gatsby';
import PrevNextPageButton from './subcomponents/PrevNextPageButton';

const prevNextPageButtonSection = (data) => {
  const { leftButton, rightButton } = data;
  // buttons look like this in the JSON
  // "leftButton": {
  //   "url": "/state-of-api/the-rise-of-api-first/", <-- only supports internal links rn
  //   "text": "Next",
  //   "isDisabled": true
  // }

  const fontFamily = data.fontFamily ? data.fontFamily : '';

  return (
    <div className="container" id="PrevNextButtonSection">
      <div className="row">
        <div className="col-12">
          <div className="text-center">
            <PrevNextPageButton
              fontFamily={fontFamily}
              text={leftButton.text}
              isDisabled={leftButton.isDisabled}
              url={withPrefix(leftButton.url)}
              iconType="left"
              className="mr-4"
            />
            <PrevNextPageButton
              fontFamily={fontFamily}
              text={rightButton.text}
              isDisabled={rightButton.isDisabled}
              url={withPrefix(rightButton.url)}
              iconType="right"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// PrimaryButton.propTypes = {
//   data: PropTypes.shape({
//     type: PropTypes.string,
//     url: PropTypes.string,
//     text: PropTypes.string,
//   }).isRequired,
//   className: PropTypes.string,
// };

export default prevNextPageButtonSection;
