import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ModalButton from './MobileTocButtonModalButtonStateOfAPI2023';
import SidebarInModal from '../sidebars/sideBar';
import linkData from './sideBarLinkData.json';
// if there is a hello bar, this is where the ToC button sits.  This "clears" the Hello bar text
// This component is the ToC button container, The actual button is MobileTocButton

const Section = styled.div`
  position: fixed;
  left: 7rem;
  border-radius: 3px;
  text-align: left;

  button.nav-toggle-button {
    z-index: 500;
    &:focus {
      outline: 0;
    }
    color: #212121;
    font-family: ${(props) => props.theme.fonts.Inter};
    font-weight: 600;
    border-radius: 3px;
    border-color: ${(props) => props.theme.colors.grey_90};
    background-color: #ffffff;
    padding: 13px 16px;
    width: auto;
    @media (max-width: 540px) {
      width: 100%;
    }
  }
`;

const ModalContent = styled.div`
  &.modal-content {
    &.video {
      background-color: transparent;
      border: 0;

      & .close {
        line-height: 1.9;
        opacity: 1;
        color: ${(props) => props.theme.colors.grey_90};
        font-weight: 400;
        text-shadow: none;
        margin-top: 60px;
        display: flex;
        align-items: center;

        & .close-label {
          font-size: 1rem !important;
          margin-right: 0.3rem;
        }

        & .close-icon {
          font-size: 19px;
        }

        &:hover {
          color: #ff6b37;
        }
      }
    }
  }
  &.modal-backdrop {
    &.show {
      opacity: 1;
    }
  }
`;

function setActiveLink(currentPath) {
  const linksArray = linkData.items;
  const linksArrayWithActive = linksArray.map((link) => {
    const linkSplit = link.url.split('#');
    const linkPath = linkSplit[0];
    const linkHash = linkSplit[1];

    if (
      currentPath === '/state-of-api/#api-global-growth' &&
      linkHash === 'api-global-growth'
    ) {
      return {
        ...link,
        active: true
      };
    }

    if (
      currentPath !== '/state-of-api/' &&
      currentPath !== '/state-of-api' &&
      link.url.includes(currentPath)
    ) {
      return {
        ...link,
        active: true
      };
    }

    if (
      (linkPath === '/state-of-api/' &&
        currentPath === '/state-of-api/' &&
        linkHash === 'key-findings') ||
      (currentPath === '/state-of-api/#key-findings' &&
        linkHash === 'key-findings')
    ) {
      return {
        ...link,
        active: true
      };
    }
    return {
      ...link
    };
  });

  return linksArrayWithActive;
}

class ModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      topVal: '59px',
      data,
      currentPath: ''
    };
  }

  componentDidMount() {
    const currentPath = window.location.pathname;
    this.setState(() => {
      return { currentPath };
    });
  }

  render() {
    const { data, topVal, currentPath } = this.state;
    return (
      <>
        <Section
          id="NavButton"
          className="d-inline d-lg-none"
          style={{ top: topVal, zIndex: 1950 }}
        >
          <ModalButton data={data} endElementId={data.endElementId} />
        </Section>
        <div
          className="modal fade bd-example-modal-lg"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ backgroundColor: '#FFFFFF', zIndex: 2010 }}
        >
          <div className="modal-dialog modal-lg" role="document">
            <ModalContent className="modal-content video">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="close-label">CLOSE</span>
                  <img
                    src="https://voyager.postman.com/icon/x-icon-grey.svg"
                    alt="X"
                  />
                </button>
                <div style={{ paddingTop: '60px' }}>
                  {SidebarInModal({
                    type: 'sideBar',
                    leftSide: true,
                    className: 'text-left w-100',
                    sidetitle: 'Table of Contents',
                    leftLinks: setActiveLink(currentPath)
                  })}
                </div>
              </div>
            </ModalContent>
          </div>
        </div>
      </>
    );
  }
}

const mobileToCButtonContainerStateOfAPI2023 = (data) => (
  <ModalWrapper data={data} />
);

ModalWrapper.propTypes = {
  data: PropTypes.shape({
    toggleText: PropTypes.string,
    backgroundColor: PropTypes.string
  }).isRequired
};

export default mobileToCButtonContainerStateOfAPI2023;
