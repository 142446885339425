//  P tag for sections
import React from 'react';
import PropTypes from 'prop-types';
/* eslint-enable */
import styled from 'styled-components';
import {
  SectionStyles,
  BaseButton,
  UnorderedListStyles,
  OrderedListStyles,
  BaseLinkStyles,
  BaseLink,
  Divider
} from 'aether-marketing';
import { withPrefix } from 'gatsby';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');

const ContentStyles = styled.div`
  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }
  ol {
    ${OrderedListStyles.componentStyle.rules}
  }
  a,
  a:hover,
  a:not([href]):not([class]) {
    ${BaseLinkStyles.componentStyle.rules}
  }
`;
const triggerGA = (category, label) => {
  return (
    category &&
    label &&
    window.pmt &&
    window.pmt('ga', ['send', 'event', category, 'Click', label])
  );
};
function TextSection({
  button,
  link,
  backgroundColor,
  paddingBottom,
  paddingTop,
  divider
}) {
  return (
    <>
      <SectionStyles
        backgroundColor={backgroundColor}
        paddingBottom={paddingBottom}
        paddingTop={paddingTop}
      >
        <div className="container">
          <div className="row">
            <div className="offset-md-0 col-lg-8 offset-lg-2">
              <ContentStyles>
                <p>
                  For the fifth year, the State of the API is the world&apos;s
                  largest and most comprehensive survey and report on APIs. More
                  than 40,000 developers and API professionals have shared their
                  thoughts on development priorities, their API tools, and where
                  they see APIs going.
                </p>
                <p>
                  Our survey-takers range from CEOs and customer success
                  managers to full-stack developers. Some work at small
                  startups, others at companies with over 5,000 developers. They
                  come from financial services, technology, healthcare, and
                  other sectors. What unites them is their interest in APIs as
                  the building blocks of modern software.
                </p>
                <p>
                  We&apos;ve expanded this year&apos;s report to cover{' '}
                  <a href={withPrefix('/apis-and-monetization/')}>
                    API monetization
                  </a>{' '}
                  and{' '}
                  <a href={withPrefix('/apis-and-generative-ai/')}>
                    generative AI
                  </a>
                  . Until now, developers have primarily designed APIs for apps
                  used by humans. Going forward, designing APIs for machines
                  will become increasingly important as AI becomes more embedded
                  in software interfaces.
                </p>
              </ContentStyles>
              {button &&
                Array.isArray(button) &&
                button.map((cta) => (
                  <div className="mb-4 mr-3 d-inline" key={pmUuid()}>
                    <BaseButton
                      buttonType={cta.buttonType}
                      as="a"
                      src={withPrefix(cta.src)}
                      target={cta.target}
                      id={cta.id}
                      gaCategory={cta.gaCategory}
                      gaLabel={cta.gaLabel}
                      className={cta.type === 'inline' ? 'pl-0' : null}
                      dataTest={cta.id}
                      onClick={() => {
                        triggerGA(cta[0].category, cta[0].label);
                      }}
                    >
                      {cta.text}
                    </BaseButton>
                  </div>
                ))}
              {link &&
                Array.isArray(link) &&
                link.map((cta) => (
                  <p key={pmUuid()}>
                    <BaseLink
                      linkType={cta.type}
                      src={withPrefix(cta.src)}
                      className={cta.className && cta.className}
                      alternative={cta.alternative ? cta.alternative : false}
                      target={cta.target ? cta.target : 'same-tab'}
                      gaCategory={cta.gaCategory && cta.gaCategory}
                      gaLabel={cta.gaLabel && cta.gaLabel}
                      id={cta.id && cta.id}
                      ariaLabel={cta.ariaLabel && cta.ariaLabel}
                      dataTest={cta.id}
                    >
                      {cta.text}
                    </BaseLink>
                  </p>
                ))}
            </div>
          </div>
        </div>
      </SectionStyles>
      {(divider === true && <Divider />) || null}
    </>
  );
}

export default TextSection;

TextSection.propTypes = {
  logo: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string
  }),
  button: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cta: PropTypes.shape({
          buttonType: PropTypes.string,
          className: PropTypes.string,
          gaCategory: PropTypes.string,
          gaLabel: PropTypes.string,
          src: PropTypes.string,
          target: PropTypes.string,
          icon: PropTypes.string,
          id: PropTypes.string
        })
      })
    ),
    PropTypes.oneOf([null]),
    PropTypes.shape({})
  ]),
  link: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        cta: PropTypes.shape({
          linkType: PropTypes.string,
          text: PropTypes.string,
          src: PropTypes.string,
          target: PropTypes.string,
          id: PropTypes.string,
          gaCategory: PropTypes.string,
          gaLabel: PropTypes.string,
          icon: PropTypes.string
        })
      })
    ),
    PropTypes.oneOf([null]),
    PropTypes.shape({})
  ]),
  backgroundColor: PropTypes.string,
  paddingBottom: PropTypes.string,
  paddingTop: PropTypes.string,
  divider: PropTypes.bool
};

TextSection.defaultProps = {
  button: {
    cta: {
      id: null,
      gaCategory: null,
      gaLabel: null,
      icon: null
    }
  },
  link: {
    cta: {
      id: null,
      gaCategory: null,
      gaLabel: null,
      icon: null
    }
  },
  logo: null,
  backgroundColor: '',
  paddingBottom: '',
  paddingTop: '',
  divider: false
};
