/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';

function LottiAnimationContainer({ animationFileName, autoplay, loop }) {
  const animationContainer = createRef();
  useEffect(() => {
    const anim = lottie.loadAnimation({
      container: animationContainer.current,
      renderer: 'svg',
      loop,
      autoplay,
      path: animationFileName
    });
    anim.pause(anim.firstFrame + 0, true);

    const container = document.querySelector('#lottie');

    function isScrolledIntoView(elem) {
      const rect = elem.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;
      const isVisible = elemTop >= 0 && elemBottom < window.innerHeight;
      return isVisible;
    }

    if (!isScrolledIntoView(container)) {
      window.addEventListener('scroll', function handleScroll() {
        if (isScrolledIntoView(container)) {
          anim.play();
        }
      });
    }

    return () => anim.destroy(); // optional clean up for unmounting
  }, []);

  return (
    <div id="lottie" className="animation-container" ref={animationContainer} />
  );
}

LottiAnimationContainer.propTypes = {
  animationFileName: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool
};

LottiAnimationContainer.defaultProps = {
  autoplay: false,
  loop: false
};

export default LottiAnimationContainer;
