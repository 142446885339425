import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ImageWrapper = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 1px;
`;

function ImageContainer({ height, src, title = null }) {
  const style = {
    backgroundImage: `url(${src})`,
    height: height || '120px'
  };
  return <ImageWrapper style={style} className="w-100" title={title} />;
}

ImageContainer.propTypes = {
  height: PropTypes.string,
  src: PropTypes.string.isRequired,
  title: PropTypes.string
};

ImageContainer.defaultProps = {
  height: '120px',
  title: null
};

export default ImageContainer;
