import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Modal from './MobileTocButtonStateOfAPI2021';
import { SidebarNavLinksStateOfAPI2021 } from './MobileModalContentsStateOfAPI2021';
import sideBarLinkData from './sideBarLinkData.json';
// if there is a hello bar, this is where the ToC button sits.  This "clears" the Hello bar text
// This component is the ToC button container, The actual button is MobileTocButton

const Section = styled.div`
  position: fixed;
  left: 3.4rem;
  border-radius: 3px;
  text-align: left;

  button.nav-toggle-button {
    &:focus {
      outline: 0;
    }
    color: #212121;
    font-family: ${(props) => props.theme.fonts.Inter};
    font-weight: 600;
    border-radius: 3px;
    border-color: ${(props) => props.theme.colors.grey_90};
    background-color: #ffffff;
    padding: 13px 16px;
    width: auto;
    @media (max-width: 540px) {
      width: 100%;
    }
  }
`;

const ModalContent = styled.div`
  &.modal-content {
    &.video {
      background-color: transparent;
      border: 0;

      & .close {
        line-height: 1.9;
        opacity: 1;
        color: ${(props) => props.theme.colors.grey_90};
        font-weight: 400;
        text-shadow: none;
        margin-top: 60px;

        & .close-label {
          font-size: 0.75rem !important;
          margin-right: 0.25rem;
          vertical-align: middle;
        }

        & .close-icon {
          font-size: 19px;
        }

        &:hover {
          color: #ff6b37;
        }
      }
    }
  }
  &.modal-backdrop {
    &.show {
      opacity: 1;
    }
  }
`;

const navData = {
  type: 'stickySideBar',
  padding: '160px auto auto auto',
  leftSide: true,
  inModal: true,
  sidetitle: 'TABLE OF CONTENTS',
  textAlign: 'text-left',
  leftLinks: sideBarLinkData.items
};

class ModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = {
      topVal: '59px',
      data
    };
  }

  render() {
    const { data, topVal } = this.state;
    return (
      <>
        <Section
          id="NavButton"
          className="d-inline d-lg-none"
          style={{ top: topVal, zIndex: 50 }}
        >
          <Modal data={data} endElementId={data.endElementId} />
        </Section>
        <div
          className="modal fade bd-example-modal-lg"
          id="myModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          style={{ backgroundColor: '#FFFFFF', zIndex: 2010 }}
        >
          <div className="modal-dialog modal-lg" role="document">
            <ModalContent className="modal-content video">
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span className="close-label">CLOSE</span>
                  <span className="close-icon" aria-hidden="true">
                    X
                  </span>
                </button>
                <div className="">
                  <SidebarNavLinksStateOfAPI2021 data={navData} />
                </div>
              </div>
            </ModalContent>
          </div>
        </div>
      </>
    );
  }
}

const stickyModalNavStateOfAPI2021 = (data) => <ModalWrapper data={data} />;

ModalWrapper.propTypes = {
  data: PropTypes.shape({
    toggleText: PropTypes.string,
    backgroundColor: PropTypes.string
  }).isRequired
};

export default stickyModalNavStateOfAPI2021;
