import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const LinkWrapper = styled.a`
  color: #3a3836;
  vertical-align: middle;
  font-family: ${(props) => props.theme.fonts.Inter};
  font-weight: 600;
  &:hover {
    color: ${(props) => props.theme.colors.orange_50};
    text-decoration: none;
  }

  &:disabled,
  &.isDisabled,
  &:not([href]) {
    color: #556267;
    cursor: not-allowed;
    font-weight: 400;

    i {
      color: #556267;
      cursor: not-allowed;
      font-weight: 400;
    }
  }
`;

function PrevNextPageButtonStateOfAPI2021(props) {
  const { text, iconType, url, isDisabled, className, fontFamily } = props;

  return (
    <LinkWrapper
      href={!isDisabled && url ? url : undefined}
      className={`${className}`}
      style={{ fontFamily }}
    >
      {iconType === 'left' && (
        <i className="mr-2 pm-icon-arrow-left align-middle" />
      )}
      {text}
      {iconType === 'right' && (
        <i className="ml-2 pm-icon-arrow-right align-middle" />
      )}
    </LinkWrapper>
  );
}

PrevNextPageButtonStateOfAPI2021.propTypes = {
  text: PropTypes.string,
  iconType: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  fontFamily: PropTypes.string
};

PrevNextPageButtonStateOfAPI2021.defaultProps = {
  text: '',
  isDisabled: false,
  className: '',
  fontFamily: ''
};

export default PrevNextPageButtonStateOfAPI2021;
