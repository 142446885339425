import React from 'react';
import styled from 'styled-components';
/* eslint-enable */

import { BaseLinkStyles } from 'aether-marketing';
import ImageContainer from '../subcomponents/ImageContainer';

/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');
/* eslint-enable */

const Section = styled.section`
  padding: 0px 0px 44px 0px;
`;

const CollapseButton = styled(BaseLinkStyles)`
  color: ${(props) => props.theme.colors.postman_orange};
  font-weight: 400;
  &:hover {
    color: #f99d78;
    border-bottom: none;
  }
  /* margin-bottom: 30px; */
`;

const QuoteContainer = styled.div`
  border: 1px solid black;
  padding: 30px 30px 30px 70px;
  box-shadow: 3px 3px 0px #686868;
  margin: 0px 0px 100px 0px;
`;

const QuoteText = styled.p`
  font-size: 24px;
  text-align: left;
  font-family: 'Inter', system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial !important;
`;

const BlockQuote = styled.div`
  position: relative;
  display: block;
  margin-left: 60px;
  margin-right: 30px;

  &&::before {
    position: absolute;
    display: block;
    font-size: 48px;
    color: #f4b396;

    content: '“';
    top: -15px;
    left: -32px;
    font-family: Georgia, 'Times New Roman', Times, serif;

    @media screen and (max-width: 576px) {
      left: -25px;
    }
  }
`;

class KeyFindings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
      items: [
        {
          image: {
            src: 'https://voyager.postman.com/sota/2021/key-findings-2-updated.svg',
            alt: 'Postman State Of The API Report.  Key Findings 2. Illustration'
          },
          title: '2) The API ecosystem is global and growing',
          body: 'Over the past 12 months, Postman users signed in from an impressive 234 different countries and geographies while making 855 million API requests (up 56% from the prior year).'
        },
        {
          image: {
            src: 'https://voyager.postman.com/sota/2021/key-findings-3-updated.svg',
            alt: 'Postman State Of The API Report.  Key Findings 3. Illustration'
          },
          title: '3) Developers are spending more time with APIs',
          body: "How much time is spent working with APIs? 49% of respondents said that more than half of the organization's development effort is spent on APIs—compare that to just over 40% last year."
        },
        {
          image: {
            src: 'https://voyager.postman.com/sota/2021/key-findings-4.svg',
            alt: 'Postman State Of The API Report.  Key Findings 4. Illustration'
          },
          title: '4) API investments stay strong',
          body: 'It appears organizations will continue investing in APIs: 94% of respondents stated that investment of time and resources into APIs will increase or stay the same for the next 12 months.'
        },
        {
          image: {
            src: 'https://voyager.postman.com/sota/2021/key-findings-5.svg',
            alt: 'Postman State Of The API Report.  Key Findings 5. Illustration'
          },
          title: '5) Quality is the top priority',
          body: 'We asked respondents to identify the top priorities for their organizations, and there was a clear winner: quality, coming in at 80%. Agility (66%) and reliability (65%) were next.'
        },
        {
          image: {
            src: 'https://voyager.postman.com/sota/2021/key-findings-6.svg',
            alt: 'Postman State Of The API Report.  Key Findings 6. Illustration'
          },
          title: '6) More companies are embracing the API-first philosophy',
          body: 'This year, 67% of survey respondents ranked themselves as a five or higher in terms of embracing an API-first philosophy, while only 62% ranked themselves in this way in 2020.'
        },
        {
          image: {
            src: 'https://voyager.postman.com/sota/2021/key-findings-7.svg',
            alt: 'Postman State Of The API Report.  Key Findings 7. Illustration'
          },
          title: '7) Being API-first pays off',
          body: 'We found that "API-first leaders" (as defined in the report) produce APIs more quickly, deploy more frequently, have fewer failures, and recover more swiftly when failures occur.'
        }
      ]
    };
    this.handleExpand = this.handleExpand.bind(this);
  }

  handleExpand() {
    const { isCollapsed } = this.state;

    this.setState({
      isCollapsed: !isCollapsed
    });
  }

  render() {
    const { isCollapsed, items } = this.state;
    const collapseText = isCollapsed ? 'Learn how organizations used APIs to respond to the pandemic' : 'Collapse';
    const collapseIcon = isCollapsed ? 'pm-icon-arrow-down' : 'pm-icon-arrow-up';
    return (
      <Section>
        <div className="container">
          <div className="row">
            <div className="col-12 text-left">
              <p>
                <strong>1) The pandemic changed the world, and the world responded with APIs</strong>
              </p>
              <p>We asked respondents to share whether the pandemic affected their work—either how their business operated or how they worked with others in their organization. Just short of two-thirds of respondents stated that the pandemic somewhat or significantly affected their work. Perhaps surprisingly, more than one-third stated that the pandemic did not affect their work. For many developers and API professionals, work continued just as it was—with many progressive organizations having already adopted a remote-friendly or remote-first approach to work.</p>
              <p>To individuals who stated that the pandemic affected their business operations or how they worked with others, we also posed the question, &quot;Were your organization&apos;s changes supported by APIs?&quot; More than half said yes.</p>
              <div className="collapse" id="collapseKeyFindings">
                <ul>
                  <li>Organizations adopted new channels or enhanced existing channels to reach and serve customers in a pandemic world. Respondents told us that common areas of investment included e-commerce, customer support via chat and text, and contactless digital systems—all of which relied on APIs.</li>
                  <li>Many respondents had to adjust to tectonic shifts in market demand, with some businesses completely pivoting their product offering. For many, product rollouts relied on APIs, and organizations pivoted in weeks and months what would normally take years. Some businesses were even born in the pandemic to meet new needs.</li>
                  <li>Working from home presented new challenges for many organizations, too. APIs and integrations with internal systems helped solve these challenges by automating or enabling tasks that used to happen in person such as communication, attendance, reporting, and project management.</li>
                  <li>Some of the most frequently cited innovations came from healthcare, with many healthcare organizations needing to digitize and automate workflows and relying on APIs to do so.</li>
                </ul>
              </div>
              <p style={{ marginBottom: '30px' }}>
                <CollapseButton onClick={this.handleExpand} data-toggle="collapse" href="#collapseKeyFindings" role="button" aria-expanded="false" aria-controls="collapseKeyFindings">
                  {`${collapseText} `}
                  <i className={`mr-2 ${collapseIcon} align-middle`} />
                </CollapseButton>
              </p>
            </div>
          </div>
          <div className="row">
            <QuoteContainer className="mx-auto col-11 col-md-10">
              <BlockQuote className="text-center">
                <QuoteText>As the pandemic suddenly changed everything, CarMax was able to quickly create and support new experiences and solutions for our customers, employees, and partners—much of which was due to APIs.&quot;</QuoteText>
                <footer className="text-left">
                  <p className="mb-0">
                    <strong>Shamim Mohammad</strong>
                    &#44; EVP and CIO/CTO at CarMax
                  </p>
                </footer>
              </BlockQuote>
            </QuoteContainer>
          </div>
          <div className="row">
            {items.map((item) => {
              return (
                <div key={pmUuid()} className="col-12 col-lg-6">
                  <div style={{ padding: '30px' }}>
                    <ImageContainer src={item.image.src} height="190px" />
                  </div>
                  <p>
                    <strong>{item.title}</strong>
                  </p>
                  <p>{item.body}</p>
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    );
  }
}

const keyFindingsTextCollapse = () => {
  return <KeyFindings />;
};

export default keyFindingsTextCollapse;
