import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import PrevNextPageButtonStateOfAPI2022 from './PrevNextPageButtonStateOfAPI2022';

function PrevNextButtonSectionStateOfAPI2022({ leftButton, rightButton }) {
  const fontFamily =
    "'Inter', system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica, Arial, sans-serif";

  return (
    <div
      className="container"
      style={{ paddingTop: '30px', paddingBottom: '60px' }}
      id="PrevNextButtonSection"
    >
      <div className="row">
        <div className="col-12">
          <div className="text-center">
            <PrevNextPageButtonStateOfAPI2022
              fontFamily={fontFamily}
              text={leftButton.text ? leftButton.text : 'Prev'}
              isDisabled={leftButton.isDisabled}
              url={withPrefix(leftButton.url)}
              iconType="left"
              className="mr-4"
            />
            <PrevNextPageButtonStateOfAPI2022
              fontFamily={fontFamily}
              text={rightButton.text ? rightButton.text : 'Next'}
              isDisabled={rightButton.isDisabled}
              url={withPrefix(rightButton.url)}
              iconType="right"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

PrevNextButtonSectionStateOfAPI2022.propTypes = {
  leftButton: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
    isDisabled: PropTypes.bool
  }).isRequired,
  rightButton: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
    isDisabled: PropTypes.bool
  }).isRequired
};

export default PrevNextButtonSectionStateOfAPI2022;
