import React from 'react';
import styled from 'styled-components';

import { UnorderedListStyles } from 'aether-marketing';
import customCharts from './handler';

const DELAY = 500;

const Container = styled.div`
  padding-top: 60px;
  padding-bottom: 60px;

  .color-key {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }
  a {
    display: inline-block;
    color: ${(props) =>
      props.alternative
        ? props.theme.colors.white
        : props.theme.colors.orange_50};
    padding: 8px 16px;
    box-shadow: none;
    text-align: center;

    &:hover {
      color: ${(props) => props.theme.colors.orange_40};
    }
  }
  ul {
    ${UnorderedListStyles.componentStyle.rules}
  }
`;

const SideBarContainer = styled.div`
  a {
    color: ${(props) =>
      props.alternative
        ? props.theme.colors.white
        : props.theme.colors.orange_50};
    /* padding: 8px 16px; */
    box-shadow: none;
    text-align: left;
    padding-left: 0;

    &:hover {
      color: ${(props) => props.theme.colors.orange_40};
    }
  }
`;

class APISurvey extends React.Component {
  componentDidMount() {
    const script = document.createElement('script');
    script.src = 'https://cdn.jsdelivr.net/npm/chart.js@2.8.0';
    script.async = true;
    document.body.appendChild(script);

    // Check to make sure ChartJS is loaded on page before calling customCarts();
    let t;
    const checker = () => {
      if (window.Chart) {
        const script2 = document.createElement('script');
        script2.src = 'https://cdn.jsdelivr.net/npm/chartjs-plugin-deferred@1';
        script2.async = true;
        document.body.appendChild(script2);

        setTimeout(() => {
          const script3 = document.createElement('script');
          script3.src =
            'https://cdn.jsdelivr.net/npm/chartjs-plugin-stacked100@0.7.1/src/index.min.js';
          script3.async = true;
          document.body.appendChild(script3);

          customCharts();
          clearTimeout(t);
        }, DELAY);
      }
    };
    t = setTimeout(checker, DELAY);
  }

  render() {
    return (
      <Container>
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <SideBarContainer className="sticky-top pt-5 mb-sm-3">
                <h3 className="h4 mb-3">Table of Contents</h3>
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  <a
                    className="active mb-3"
                    id="key-findings-tab"
                    data-toggle="pill"
                    href="#key-findings"
                    role="tab"
                    aria-controls="key-findings"
                    aria-selected="true"
                    onClick={() => {
                      window.scrollTo(0, 700);
                    }}
                  >
                    KEY FINDINGS
                  </a>
                  <a
                    className="mb-3"
                    id="who-works-with-apis-tab"
                    data-toggle="pill"
                    href="#who-works-with-apis"
                    role="tab"
                    aria-controls="who-works-with-apis"
                    aria-selected="false"
                    onClick={() => {
                      window.scrollTo(0, 700);
                    }}
                  >
                    WHO WORKS WITH APIS?
                  </a>
                  <a
                    className="mb-3"
                    id="a-day-in-the-life-tab"
                    data-toggle="pill"
                    href="#a-day-in-the-life"
                    role="tab"
                    aria-controls="a-day-in-the-life"
                    aria-selected="false"
                    onClick={() => {
                      window.scrollTo(0, 700);
                    }}
                  >
                    A DAY (OR WEEK) IN THE LIFE OF AN API TEAM
                  </a>
                  <a
                    className="mb-3"
                    id="performance-security-tab"
                    data-toggle="pill"
                    href="#performance-security"
                    role="tab"
                    aria-controls="performance-security"
                    aria-selected="false"
                    onClick={() => {
                      window.scrollTo(0, 700);
                    }}
                  >
                    APIS: PERFORMANCE, SECURITY, AND MORE
                  </a>
                  <a
                    className="mb-3"
                    id="terminology-tab"
                    data-toggle="pill"
                    href="#terminology"
                    role="tab"
                    aria-controls="terminology"
                    aria-selected="false"
                    onClick={() => {
                      window.scrollTo(0, 700);
                    }}
                  >
                    WORDS: INDUSTRY TERMINOLOGY AND SLANG
                  </a>
                </div>
              </SideBarContainer>
            </div>
            <div className="col-lg-9">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="key-findings"
                  role="tabpanel"
                  aria-labelledby="key-findings-tab"
                >
                  <div className="container">
                    <div className="row">
                      <h2 className="mb-3">2019 Survey: Key Findings</h2>
                      <ul className="key-findings v5_vertical-lists-bullets">
                        <li>
                          <p>
                            APIs go beyond those who code&mdash;it&#39;s not
                            just a developer&#39;s game anymore. More
                            non-developers are working with APIs than in prior
                            years and there is greater diversity in where people
                            work.
                          </p>
                        </li>
                        <li>
                          <p>
                            When it comes to developing APIs, there is a
                            dichotomy of experience. A small, but significant
                            segment of developers have 10 or more years of
                            experience, compared to more than three-quarters who
                            have 5 or fewer years of experience.
                          </p>
                        </li>
                        <li>
                          <p>
                            While more than half of respondents shared that
                            their organization employs more than 25 developers,
                            nearly three-quarters are assigned to teams of 10 or
                            less, indicating that most teams are structured to
                            be more responsive and agile. As teams grow in size,
                            so do the complexity and number of APIs they work
                            on.
                          </p>
                        </li>
                        <li>
                          <p>
                            While more time is spent working with internal APIs,
                            and this segment has remained steady, there is a
                            shift from using public APIs to using partner APIs
                            when compared to prior years.
                          </p>
                        </li>
                        <li>
                          <p>
                            There is a great discrepancy between how people
                            spend time, and how they feel they should spend
                            time. Manual testing and debugging consume far more
                            time than desired.
                          </p>
                        </li>
                        <li>
                          <p>
                            Documentation could be better&mdash;a lot better.
                            Over half of those taking the survey felt that API
                            documentation was below average or not well
                            documented. That said, survey respondents shared
                            specific and actionable insights to improve
                            documentation.
                          </p>
                        </li>
                        <li>
                          <p>
                            Microservices, containers, and serverless
                            architecture are among the most exciting
                            technologies for developers in the next year.
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="who-works-with-apis"
                  role="tabpanel"
                  aria-labelledby="who-works-with-apis-tab"
                >
                  <h2 className="mb-3">Who Works with APIs?</h2>
                  <div className="info-section">
                    <h3 className="h4 mb-3">Roles</h3>
                    <p>
                      While more developers work with Application Programming
                      Interfaces than anyone else in a typical organization,
                      APIs reach many more people than those who code. Working
                      directly with APIs has become part of a surprising number
                      of positions, including technical writers and executives,
                      and this diversity appears to be part of a larger trend.
                      Last year, 58.6% of respondents identified as either a
                      front-end or back-end developer, compared to only 46.6%
                      this year.
                    </p>
                    <canvas className="mb-4" id="whoWorksWithApis_roles" />
                  </div>
                  <div className="info-section">
                    <h3 className="h4 mb-3">Areas</h3>
                    <p>
                      Not only are the roles of those who work with APIs
                      diverging, so are the areas where they work. Agile
                      development and microservices lead the way, followed by
                      API/technical support, and various disciplines in testing
                      and integration.
                    </p>
                    <canvas
                      className="mb-4"
                      id="whoWorksWithApis_areas"
                      height="230"
                    />
                  </div>
                  <div className="info-section">
                    <h3 className="h4 mb-3">Industry</h3>
                    <p>
                      People are working with APIs in multiple industries. The
                      majority of respondents (52.3%) stated that they work in
                      technology, followed by 41.2% who work in business and/or
                      IT services. Banking and finance round out the top 3,
                      trailed by healthcare, retail, and manufacturing. Finally,
                      the bottom three industries are comprised by
                      government/defense, advertising/agencies, and nonprofits.
                      Quite a few folks (11.6%) shared that their organization
                      didn&#39;t match these categories and selected
                      &#34;Other,&#34; as their industry. This distribution of
                      industries closely maps what we found in 2018.
                    </p>
                    <canvas className="mb-4" id="whoWorksWithApis_industry" />
                  </div>
                  <div className="info-section">
                    <h3 className="h4 mb-3">
                      Years of API Development Experience
                    </h3>
                    <p>
                      When it comes to developing APIs, there is a range of
                      experience levels. A significant segment of developers,
                      12.2%, have 10 or more years of experience, compared to
                      78.2% of developers who have 5 or fewer years of
                      experience. With so many new developers joining the ranks
                      each year, industry veterans may find it challenging to
                      bring them up to speed quickly.
                    </p>
                    <canvas
                      className="mb-4"
                      id="whoWorksWithApis_yearsOfExperience"
                    />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="a-day-in-the-life"
                  role="tabpanel"
                  aria-labelledby="a-day-in-the-life-tab"
                >
                  <h2 className="mb-3">
                    A Day (or Week) in the Life of an API Team
                  </h2>
                  <div className="info-section">
                    <h3 className="h4 mb-3">Organization and Team Structure</h3>
                    <p>
                      API teams tend to be 10 members or less, with nearly
                      three-quarters of individuals stating that they belong to
                      groups of this size. Large teams are rare; only 1.7% of
                      those taking the survey reported that 50 people or more
                      belong to their team.
                    </p>
                    <div className="row">
                      <div className="col-lg-8">
                        <canvas
                          id="aDayInTheLife_teamStructures"
                          height="210"
                          className="mb-4"
                        />
                      </div>
                      <div
                        id="aDayInTheLife_teamStructures-legend"
                        className="col-6 col-md-4 mx-auto"
                      />
                    </div>
                    <p>
                      Comparing team size to the number of developers
                      organizations employ, we find the inverse is true. Over
                      half of companies employ more than 25 developers, giving
                      us insights into team structures. While many organizations
                      employ substantial numbers of developers, they are broken
                      into much smaller teams to align with organizational
                      needs.
                    </p>
                    <canvas
                      id="aDayInTheLife_teamStructures_1"
                      className="mb-4"
                    />
                  </div>
                  <div className="info-section">
                    <h3 className="h4 mb-3">Amount of Time Spent</h3>
                    <p>
                      When we asked how much time respondents spend working with
                      APIs, we found that 37.3% spend less than 10 hours per
                      week and 35.7% spend 10 - 20 hours per week. The remaining
                      27% spend more than 20 hours per week, compared to 31.7%
                      in 2018. Interestingly, the portion of the population
                      spending the most time is not shrinking because
                      they&#39;re working on APIs less, but rather the
                      population as a whole is changing&mdash;with more new job
                      roles and areas joining the API ecosystem.
                    </p>
                    <div className="row">
                      <div className="col-lg-8">
                        <canvas
                          id="aDayInTheLife_timeSpent"
                          height="210"
                          className="mb-4"
                        />
                      </div>
                      <div
                        id="aDayInTheLife_timeSpent-legend"
                        className="col-7 col-md-4 mx-auto"
                      />
                    </div>
                  </div>
                  <div className="info-section">
                    <h3 className="h4 mb-3">How Time is Spent</h3>
                    <p>
                      What do people do in the time that they spend on APIs?
                      More time (26.1%) is spent on development than any other
                      task, followed by debugging and manual testing (22.2%),
                      automated testing (11.4%), and designing and mocking APIs
                      (11.2%). Other tasks make up less than 10% of time each,
                      including managing others (9.1%), API documentation
                      (7.3%), API monitoring (5.7%), publishing APIs (3.6%), and
                      writing about APIs (3.3%).
                    </p>
                    <canvas
                      id="aDayInTheLife_howTimeIsSpent"
                      className="mb-4"
                    />
                  </div>
                  <div className="info-section">
                    <h3 className="h4 mb-3">How Time Should Be Spent</h3>
                    <p>
                      When people compared where time is actually spent with
                      where it should be spent, the good news is that API
                      development tops both lists. However, there is one large
                      disconnect; respondents spend far more time&mdash;70%
                      more&mdash;on manual testing and debugging than they feel
                      like they should. Instead of testing or figuring out why
                      an API isn&#39;t working, they feel that time should be
                      spent on designing and automating testing.
                    </p>
                    <canvas
                      id="aDayInTheLife_howTimeShouldBeSpent"
                      className="mb-4"
                    />
                  </div>
                  <div className="info-section">
                    <h3 className="h4 mb-3">How APIs are Learned</h3>
                    <p>
                      Overwhelmingly, individuals gain API knowledge by working
                      with APIs on the job and through colleagues. This is
                      followed by a nearly even mix of published documentation
                      and online resources / classes. Seeking information from
                      online communities rounds out the top four methods.
                    </p>
                    <canvas
                      id="aDayInTheLife_howApisAreLearned"
                      className="mb-4"
                    />
                  </div>
                  <div className="info-section">
                    <h3 className="h4 mb-3">Number of APIs</h3>
                    <p>
                      Most developers and team members work with only a handful
                      of APIs, while a smaller segment works with a large number
                      of APIs. About 13% of those taking the survey report that
                      they work with more than 50 APIs, and not surprisingly, as
                      the number of APIs increased, so did the average number of
                      people on their team.
                    </p>
                    <div className="row">
                      <div className="col-lg-8">
                        <canvas
                          id="aDayInTheLife_numberOfApis"
                          height="210"
                          className="mb-4"
                        />
                      </div>
                      <div
                        id="aDayInTheLife_numberOfApis-legend"
                        className="col-4 mx-auto"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="performance-security"
                  role="tabpanel"
                  aria-labelledby="performance-security-tab"
                >
                  <h2 className="mb-3">
                    APIs: Performance, Security, and More
                  </h2>
                  <div className="info-section">
                    <h3 className="h4 mb-3">
                      APIs: Internal, External, and Partners
                    </h3>
                    <p>
                      Survey respondents shared that the majority of the APIs
                      (52.8%) they work with are internal, only used by their
                      teams and organizations, which is on par with our findings
                      in 2018. While internal APIs have remained steady, there
                      has been a shift from public APIs to partner APIs over the
                      last year. In 2019, 28.4% of APIs used were shared only
                      among integration partners, compared to 25.7% in 2018.
                      Meanwhile, the percentage of time spent on public APIs
                      openly available on the web dropped from 21.8% to 18.8%.
                    </p>
                    <div className="row">
                      <div className="col-lg-8">
                        <canvas
                          id="performanceSecurity_internalExternal"
                          height="210"
                          className="mb-4"
                        />
                      </div>
                      <div
                        id="performanceSecurity_internalExternal-legend"
                        className="col-4 mx-auto"
                      />
                    </div>
                  </div>
                  <div className="info-section">
                    <h3 className="h4 mb-3">Performance & Change</h3>
                    <p>
                      When it comes to API performance, close to half of
                      respondents feel that their APIs do not break, stop
                      working, or materially change specification often enough
                      to matter. The other half of respondents felt that
                      breakages and changes occurred on a monthly (28.4%),
                      weekly (15.7%), or daily (3.2%) basis. The silver lining?
                      Only 3.5% of respondents shared that breakages and changes
                      occurred too frequently.
                    </p>
                    <canvas
                      className="mb-4"
                      id="performanceSecurity_performanceChange"
                    />
                  </div>
                  <div className="info-section">
                    <h3 className="h4 mb-3">Security</h3>
                    <p>
                      While API security is a hot topic&mdash;driven by frequent
                      reports of API security breaches and misuse&mdash;the
                      industry feels differently. Nearly three-quarters of
                      respondents feel that their APIs are, &#34;very secure,
                      &#34; or have &#34;above average security.&#34; Only 2.4%
                      stated that their APIs were not at all secure.
                    </p>
                    <canvas
                      className="mb-4"
                      id="performanceSecurity_security"
                    />
                  </div>
                  <div className="info-section">
                    <h3 className="h4 mb-3">Documentation</h3>
                    <p>
                      Documentation could be better&mdash;a lot better. Over
                      half of those taking the survey felt that API
                      documentation was below average or not well documented.
                      Compared to 28.2% of respondents who felt that
                      documentation was above average or very well documented,
                      this suggests a compelling reason for teams to spend more
                      time documenting APIs.
                    </p>
                    <canvas
                      id="performanceSecurity_documentation"
                      className="mb-4"
                    />
                    <p>
                      What does it take to improve documentation? Our
                      respondents had insights into that, too. The most helpful
                      enhancement API producers can make is to provide better
                      examples in the documentation (63.5%), followed by
                      standardization (59.4%), and sample code (57.8%). API
                      consumers also find real-world use cases, better
                      workflows, additional tools and SDKs helpful, although to
                      a lesser extent.
                    </p>
                    <canvas
                      className="mb-4"
                      id="performanceSecurity_documentation_1"
                    />
                  </div>
                  <div className="info-section">
                    <h3 className="h4 mb-3">Exciting Technologies</h3>
                    <p>
                      Microservices, containers, and serverless architecture are
                      among the most exciting technologies for developers in the
                      next year, cited by 53.9%, 45.5% , and 44.0% of
                      respondents respectively. Slightly less exciting, but
                      still on developers&#39; radars, are OpenAPI 3.0, GraphQL,
                      HTTP 2.0, and WebSockets.
                    </p>
                    <canvas
                      id="performanceSecurity_excitingTechnologies"
                      className="mb-4"
                    />
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="terminology"
                  role="tabpanel"
                  aria-labelledby="terminology-tab"
                >
                  <h2 className="mb-3">
                    Words: Industry Terminology and Slang
                  </h2>
                  <div className="info-section">
                    <p>
                      Every industry has its own way of speaking&mdash;from
                      technical terminology to jargon to humorous slang. We
                      asked respondents to share which phrases they use, which
                      are their favorite, and which they&#39;ve never heard. The
                      winner? &#34;Deprecate&#34;&mdash;a label given to tools,
                      protocols and features that are being replaced with newer
                      versions&mdash;took the top spot.
                    </p>
                    <canvas id="words_usage" className="mb-4" />
                    <div
                      id="words_usage-legend"
                      className="col-5 col-lg-4 mx-auto"
                    />
                    <p>
                      We also asked which of these phrases have made the leap to
                      being understood by others outside of our industry. Not
                      surprisingly, deprecate took the top spot here, too;
                      it&#39;s often necessary to explain to non-technical
                      friends and family why they need to upgrade. Another
                      popular term is &#34;happy path,&#34; or a default
                      scenario with no errors or exceptions.
                    </p>
                    <canvas id="words_outsideOfSoftware" className="mb-5" />
                    <div
                      id="words_outsideOfSoftware-legend"
                      className="col-md-10 col-lg-8 mx-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <script src="https://cdn.jsdelivr.net/npm/chart.js@2.8.0"></script> */}
        {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/Chart.js/2.8.0/Chart.bundle.js" integrity="sha256-qSIshlknROr4J8GMHRlW3fGKrPki733tLq+qeMCR05Q=" crossorigin="anonymous"></script> */}
      </Container>
    );
  }
}

const apiSurvey2019 = (data) => (
  <div>
    <APISurvey data={data} />
  </div>
);

export default apiSurvey2019;
