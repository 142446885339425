import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BaseLinkStyles } from 'aether-marketing';

export const LinkWrapper = styled(BaseLinkStyles)`
  color: ${(props) => props.theme.colors.blue_60}!important;

  &:disabled,
  &.isDisabled,
  &:not([href]) {
    color: ${(props) => props.theme.colors.grey_60}!important;
    cursor: not-allowed;
    font-weight: 400;
    :hover {
      border: none;
    }
    i {
      color: ${(props) => props.theme.colors.grey_60};
      cursor: not-allowed;
      font-weight: 400;
    }
  }
`;

function PrevNextPageButtonStateOfAPI2022(props) {
  const { text, iconType, url, isDisabled, className, fontFamily } = props;

  return (
    <LinkWrapper
      href={!isDisabled && url ? url : undefined}
      className={`${className}`}
      style={{ fontFamily }}
    >
      {iconType === 'right' ? `${text} →` : `← ${text}`}
    </LinkWrapper>
  );
}

PrevNextPageButtonStateOfAPI2022.propTypes = {
  text: PropTypes.string,
  iconType: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  fontFamily: PropTypes.string
};

PrevNextPageButtonStateOfAPI2022.defaultProps = {
  text: '',
  isDisabled: false,
  className: '',
  fontFamily: ''
};

export default PrevNextPageButtonStateOfAPI2022;
