import React from 'react';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
/* eslint-enable */
import {
  SectionHeader,
  BaseLink,
  BaseButton,
  SectionStyles
} from 'aether-marketing';
/* eslint-disable import/no-extraneous-dependencies */
const pmUuid = require('@postman/uuid');

function KeyFindings({ items, button }) {
  return (
    <SectionStyles id="key-findings" className="pb-5">
      <div className="container">
        <SectionHeader
          title="Seven key findings"
          textAlign="center"
          className="pb-2"
          bottomMargin="30px"
        />
        <div>
          {items.map((item) => (
            <div key={pmUuid()} className="row align-items pb-5 mt-5">
              <div className="col-lg-3 pb-3 pb-lg-0 offset-lg-1">
                <img
                  src={item.image.src}
                  alt={item.image.alt}
                  height="190px"
                  width="auto"
                  fetchpriority="low"
                  loading="lazy"
                />
              </div>
              <div className="col-lg-7">
                <h3 className="">{item.title}</h3>
                <p>{item.body}</p>
                <BaseLink
                  linkType="arrowLink"
                  src={withPrefix(item.link.src)}
                  target={
                    item.link.target ? item.link.target : 'new-tab-postman'
                  }
                >
                  {item.link.text}
                </BaseLink>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div />
      <div className="text-center">
        <BaseButton
          buttonType="secondary"
          as="a"
          src={withPrefix(button.src)}
          target={button.target}
          id={button.id}
          gaCategory={button.gaCategory}
          gaLabel={button.gaLabel}
          className="mt-2"
        >
          {button.text}
        </BaseButton>
      </div>
    </SectionStyles>
  );
}

export default KeyFindings;

KeyFindings.propTypes = {
  items: PropTypes.shape(),
  button: PropTypes.shape()
};
KeyFindings.defaultProps = {
  items: [],
  button: []
};
