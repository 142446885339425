import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { eyeBrowStickySOTA2022 } from '../../../utils/SOTA2022-stickyElement';

const Section = styled.div`
  z-index: 10;
  position: sticky;
`;

// NOTICE THE MARGIN LEFT -51px margin-left
// and WIDTH 117%
// This is to clear any overhang, resulting from the new padding px-lg-5 on splitTemplate
const Wedge = styled.div`
  background-color: #ffffff;
  width: 117%;
  margin-left: -51px;
  height: 10px;

  @media (min-width: 992px) {
    height: 80px;
  }
`;

const OrangeLine = styled.div`
  /* width: 97px; */
  width: 0px;
  height: 2px;
  background-color: #ff8a01;
  margin: 0 0 30px 0;
  opacity: 0;
  transition: 0.3s;
`;

const Title = styled.h2`
  transition: 0.3s;
`;

// NOTICE THE NEGATIVE MARGIN BELOW!!
// Coorosponds with 2022SplitTemplate px-lg-5
// YOU ALSO NEED TO UPDATE THE SOTA2022-stickyElement.js
const BodyContainer = styled.div`
  padding-top: 30px;

  @media (min-width: 992px) {
    margin: 0 -50px 0 -50px;
    border-top: 1px solid #e6e6e6;

    .extra-padding-offset-2022 {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
`;

class HeaderRow extends React.Component {
  constructor(props) {
    super(props);
    const { data } = this.props;
    this.state = { data };
  }

  componentDidMount() {
    const { data } = this.state;
    const { elementId, parentContainerId, underlineId, endElementId } = data;
    // Requires that IDs include # sign in string.. ex ('#elementId')
    eyeBrowStickySOTA2022(
      elementId,
      parentContainerId,
      underlineId,
      28,
      endElementId,
      '#eyebrowBodyContainer'
    );
  }

  render() {
    const { data } = this.state;
    const fontWeight = data.fontWeight ? data.fontWeight : '';
    const fontFamily = data.fontFamily ? data.fontFamily : '';

    return (
      <>
        <Wedge className="d-none d-lg-block" />
        <BodyContainer id="eyebrowBodyContainer">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="extra-padding-offset-2022">
                  <Title
                    style={{ fontFamily, fontWeight, marginBottom: '8px' }}
                    className="d-inline-block h3"
                  >
                    {data.text}
                  </Title>
                  <OrangeLine id="OrangeLineDivider" />
                </div>
                {/* {headerType} */}
              </div>
            </div>
          </div>
        </BodyContainer>
      </>
    );
  }
}

const headerRowStickyShrinkStateOfAPI2022 = (data) => (
  <Section
    id={`${data.elementId && data.elementId.replace('#', '')}`}
    style={{ backgroundColor: data.backgroundColor }}
    className="sticky-top"
  >
    <HeaderRow data={data} />
  </Section>
);

export default headerRowStickyShrinkStateOfAPI2022;

HeaderRow.propTypes = {
  data: PropTypes.shape({
    fontWeight: PropTypes.string,
    fontFamily: PropTypes.string,
    elementId: PropTypes.string,
    parentContainerId: PropTypes.string,
    underlineId: PropTypes.string,
    endElementId: PropTypes.string,
    alternative: PropTypes.bool,
    text: PropTypes.string
  }).isRequired
};
