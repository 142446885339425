/* global $ */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

// This is the actual button for the mobile ToC button

// if there is a hello bar, this is where the ToC button sits.  This "clears" the Hello bar text
const topValIfHello = 136;

const Icon = styled.i`
  font-size: 9px;
`;

function turnOnModal() {
  document.querySelectorAll('.nav-toggle-button').forEach((btn) => {
    btn.addEventListener('click', () => {
      const modal = document.querySelector('#myModal');
      modal.style.display = 'block';
      modal.style.paddingRight = '17px';
      modal.className = 'modal fade show';
    });
  });
}

// Below is for ToC mobile button on state-of-api 2021
function positionMobileNav({
  elementId, // ToC ID
  startPoint, // Initial top value of ToC
  paddingTop, // little bit of space above and below
  endElementId
}) {
  $(window).on('scroll', () => {
    // Note: the "+ 60" represents the padding-top: 60px that lives on the Eyebrow during it's initial, unshrunk state
    if (
      $(window).scrollTop() >
        $(endElementId).offset().top -
          $(elementId).height() -
          paddingTop +
          60 &&
      $(elementId).css('position') === 'fixed'
    ) {
      $(elementId).css('position', 'absolute');
      $(elementId).css(
        'top',
        $(endElementId).offset().top + $(elementId).height() + 10
      );
    }
    if (
      $(window).scrollTop() <= $(endElementId).offset().top &&
      $(elementId).css('position') === 'absolute'
    ) {
      $(elementId).css('position', 'fixed');
      $(elementId).css('top', startPoint);
    }
  });
}

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    const { data, setTopVal, endElementId } = this.props;
    this.state = { data, setTopVal, endElementId };
  }

  componentDidMount() {
    let hello;
    const { setTopVal, endElementId } = this.state;
    setTimeout(() => {
      hello = document.getElementById('message-bar-alert-top');
      const options = {
        elementId: '#NavButton',
        startPoint: 64,
        paddingTop: 10,
        endElementId
      };
      if (hello !== null) {
        setTopVal();
        positionMobileNav('#NavButton', topValIfHello, 10, endElementId);
      } else {
        positionMobileNav(options);
      }
    }, 1000);
  }

  render() {
    const { data } = this.state;
    return (
      <div className="mr-0">
        <button
          onClick={() => {
            turnOnModal();
          }}
          className="nav-toggle-button"
          type="button"
          data-toggle="modal"
          data-target="#myModal"
        >
          {data.toggleText}
          <Icon className="ml-3 pm-icon-arrow-down align-middle" />
        </button>
      </div>
    );
  }
}

Modal.defaultProps = {
  setTopVal: null
};

Modal.propTypes = {
  data: PropTypes.shape({
    toggleText: PropTypes.string,
    backgroundColor: PropTypes.string
  }).isRequired,
  setTopVal: PropTypes.func,
  endElementId: PropTypes.string.isRequired
};
